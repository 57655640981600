export const MORNING_SLOTS = [{ value: '08:00 AM - 08:30 AM', id: 1, format: '08:00-08:30' },
{ value: '08:30 AM - 09:00 AM', id: 2, format: '08:30-09:00' },
{ value: '09:00 AM - 09:30 AM', id: 3, format: '09:00-09:30' },
{ value: '09:30 AM - 10:00 AM', id: 4, format: '09:30-10:00' },
{ value: '10:00 AM - 10:30 AM', id: 5, format: '10:00-10:30' },
{ value: '10:30 AM - 11:00 AM', id: 6, format: '10:30-11:00' },
{ value: '11:00 AM - 11:30 AM', id: 7, format: '11:00-11:30' },
{ value: '11:30 AM - 12:00 PM', id: 8, format: '11:30-12:00' },
{ value: '12:00 PM - 12:30 PM', id: 9, format: '12:00-12:30' },
{ value: '12:30 PM - 01:00 PM', id: 10, format: '12:30-13:00' },
{ value: '01:00 PM - 01:30 PM', id: 11, format: '13:00-13:30' },
{ value: '01:30 PM - 02:00 PM', id: 12, format: '13:30-14:00' },
{ value: '02:00 PM - 02:30 PM', id: 13, format: '14:00-14:30' },
{ value: '02:30 PM - 03:00 PM', id: 14, format: '14:30-15:00' },
{ value: '03:00 PM - 03:30 PM', id: 15, format: '15:00-15:30' },
{ value: '03:30 PM - 04:00 PM', id: 16, format: '15:30-16:00' }];