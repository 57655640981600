import { AM_SLOTS } from './../../../core/constants/anti-meridian-slots.constants';
import { PM_SLOTS } from './../../../core/constants/past-meridian-slots.constants';
import { ClientService } from './../../../core/services/client.service';
import { UserService } from './../../../core/services/user.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { MachinesService } from 'src/app/core/services/machines.service';
import { DAYS } from './../../../core/constants/days.constants';
import { REGEX_PATTERNS } from 'src/app/core/constants/regex-patterns.constant';
import { MORNING_SLOTS } from 'src/app/core/constants/morning-slots.constants';
import { EVENING_SLOTS } from 'src/app/core/constants/evening-slots.constants';
import { NIGHT_SLOTS } from 'src/app/core/constants/night-slots';

@Component({
  selector: 'app-assign-machine-to-user',
  templateUrl: './assign-machine-to-user.component.html',
  styleUrls: ['./assign-machine-to-user.component.scss']
})
export class AssignMachineToUserComponent implements OnInit {

  @Input() operatorId;
  @Input() cityid;
  @Input() supId;
  assignMachineForm: any;
  clients: any;
  machines: any;
  cities: any;
  cityId: any;
  spanId: any;
  sId: any = 1;
  days: any = DAYS;
  daysArray: any = [];
  // timeSlots: any = TIME_SLOTS;
  // allSlots: any = [...TIME_SLOTS];
  morningSlots: any = [...MORNING_SLOTS];
  eveningSlots: any = [...EVENING_SLOTS];
  nightSlots: any = [...NIGHT_SLOTS];
  timeSlots: any = [...MORNING_SLOTS];
  refills_Per_day: any = 0;
  day_ids: any = [];
  time_slots: any = [];
  schedules: any;
  slot: any = 'morning';
  machineId: any;
  refills = 1;
  spans: any = [{ id: 1, title: 'Before Noon' }, { id: 2, title: 'After Noon' }];
  currentTime: any;
  m_slotArray: any = [];
  e_slotArray: any = [];
  n_slotArray: any = [];

  constructor(private activeModal: NgbActiveModal,
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private machinesService: MachinesService,
    private clientService: ClientService) {
    this.morningSlots = [...MORNING_SLOTS];
    this.eveningSlots = [...EVENING_SLOTS];
    this.nightSlots = [...NIGHT_SLOTS];
    this.timeSlots = [...MORNING_SLOTS];
  }

  ngOnInit() {
    this.getCities();
    this.initializeForm();
  }

  total_length() {
    let t = this.m_slotArray.length + this.e_slotArray.length + this.n_slotArray.length;
    return t;
  }

  onDayAdd(item) {
    // this.setSlotsByMeridian(this.sId);
    this.day_ids.push(item.id);
    // this.assignMachineForm.controls['time_slot'].setValue(time_slot, {
    //   onlySelf: true
    // });
    this.getSchedulesByDays();
  }

  onDayRemove(item) {
    if (this.day_ids.length) {
      for (let i = 0; i < this.day_ids.length; i++) {
        const day_id = this.day_ids[i];
        if (day_id === item.id) {
          this.day_ids.splice(i, 1);
        }
      }
    }
    if (this.day_ids.length) {
      this.getSchedulesByDays();
    }
  }

  onTimeAdd(item, slot) {
    // let total = this.total_length;
    switch (slot) {
      case 'morning':
        if (this.total_length() > this.refills) {
          // this.m_slotArray = [];
          this.toastr.error('Time slot count should be equal to number of refill', 'Error !');
          this.m_slotArray.splice(-1, 1);
        } else {
          this.addValue(item, slot);
        }
        break;
      case 'evening':
        if (this.total_length() > this.refills) {
          // this.e_slotArray = [];
          this.toastr.error('Time slot count should be equal to number of refill', 'Error !');
          this.e_slotArray.splice(-1, 1);
        } else {
          this.addValue(item, slot);
        }
        break;
      case 'night':
        if (this.total_length() > this.refills) {
          // this.n_slotArray = [];
          this.toastr.error('Time slot count should be equal to number of refill', 'Error !');
          this.n_slotArray.splice(-1, 1);
        } else {
          this.addValue(item, slot);
        }
        break;
      default:
        break;
    }
  }

  addValue(item, slot) {
    // this.setCounter(slot);
    const slots = item.format.split('-');
    this.time_slots.push(slots);
  }

  onTimeRemove(item) {
    if (this.time_slots.length) {
      const value = item.format;
      for (let i = 0; i < this.time_slots.length; i++) {
        const slot = this.time_slots[i].join('-');
        if (slot === value) {
          this.time_slots.splice(i, 1);
        }
      }
    }
  }

  closeDialog() {
    this.activeModal.dismiss('dismissed');
  }

  initializeForm() {
    this.assignMachineForm = this.fb.group({
      city_id: [this.cityid, [Validators.required]],
      refills_Per_day: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.REFILLS)]],
      client_id: ['', [Validators.required]],
      machine_id: ['', [Validators.required]],
      day_slot: ['', [Validators.required]],
      // span_id: [1, [Validators.required]],
      time_slot1: [''],
      time_slot2: [''],
      time_slot3: ['']
    });
  }

  getSchedulesByDays() {
    this.machinesService.getSchedulesByDays(this.day_ids, this.operatorId, null).subscribe((res) => {
      if (res.status === 'success') {
        this.schedules = res.scheduled_time_slots;
        if (this.schedules.length) {
          this.removeAllocatedTimeSlots();
        }
      } else {
        this.setSlotsByMeridian();
      }
    }, (err) => {
      console.log(err);
    });
  }

  removeAllocatedTimeSlots() {
    if (this.timeSlots.length) {
      for (let i = 0; i < this.timeSlots.length; i++) {
        for (let j = 0; j < this.schedules.length; j++) {
          if (this.schedules[j] === this.timeSlots[i].format) {
            this.timeSlots.splice(i, 1);
            i = 0;
            j = 0;
          }
        }
      }
    }
  }

  setRefill() {
    // this.assignMachineForm.controls['time_slot1'].setValue([]);
    // this.assignMachineForm.controls['time_slot2'].setValue([]);
    // this.assignMachineForm.controls['time_slot3'].setValue([]);
    // this.time_slots = [];
    this.refills = this.assignMachineForm.controls['refills_Per_day'].value;
  }

  getCities() {
    this.userService.getCities().subscribe((res) => {
      if (res.status === 'success') {
        this.cities = res.cities;
        this.getClients(this.cityid);
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  changeCity(e) {
    this.cityId = e.target.value;
    this.assignMachineForm.controls['city_id'].setValue(this.cityId, {
      onlySelf: true
    });
    this.getClients(this.cityId);
  }

  // changeSpan(e) {
  //   this.currentTime = [];
  //   this.spanId = e.target.value;
  //   this.setSlotsByMeridian(this.spanId);
  //   this.assignMachineForm.controls['span_id'].setValue(this.spanId, {
  //     onlySelf: true
  //   });
  //   this.getSchedulesByDays();
  // }

  setSlotsByMeridian() {
    this.timeSlots = [];
    this.setSlotsByTime(this.slot);
  }

  setSlotsByTime(slot) {
    this.slot = slot;
    switch (slot) {
      case 'morning':
        this.timeSlots = [...this.morningSlots];
        break;
      case 'evening':
        this.timeSlots = [...this.eveningSlots];
        break;
      case 'night':
        this.timeSlots = [...this.nightSlots];
        break;
      default:
        break;
    }
    this.getSchedulesByDays();
  }

  getClients(city) {
    this.clientService.getClientsByCity(city).subscribe((res) => {
      if (res.status === 'success') {
        this.clients = res.client_details;
      } else {
        console.log(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  getMachinesByClient(clientId) {
    this.userService.getMachinesByClient(clientId, this.supId, false).subscribe((res) => {
      if (res.status === 'success') {
        this.machines = res.machines;
      } else {
        console.log(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  changeClient(e) {
    const clientId = e.target.value;
    this.assignMachineForm.controls['client_id'].setValue(clientId, {
      onlySelf: true
    });
    this.setMachines(clientId);
  }

  changeMachine(e) {
    this.machineId = e.target.value;
    this.assignMachineForm.controls['machine_id'].setValue(this.machineId, {
      onlySelf: true
    });
  }

  setMachines(clientId) {
    this.getMachinesByClient(clientId);
  }

  selectAll(e) {
    let checked = e.target.checked;
    if (checked) {
      this.daysArray = [];
      this.addAllDays();
    } else {
      this.removeAllDays();
    }
  }

  addAllDays() {
    this.day_ids = [];
    for (let i = 0; i < this.days.length; i++) {
      let day = this.days[i];
      this.day_ids.push(day.id);
      this.daysArray.push({
        id: day.id,
        value: day.value,
        display: day.display
      });
    }
    this.day_ids = [1, 2, 3, 4, 5, 6, 7];
    this.getSchedulesByDays();
    this.assignMachineForm.controls['day_slot'].setValue(this.daysArray);
  }

  removeAllDays() {
    this.daysArray = [];
    this.day_ids = [];
    this.assignMachineForm.controls['day_slot'].setValue([]);
  }

  onSubmit() {
    if (this.total_length() != this.refills) {
      this.toastr.error('Time slot count should be equal to number of refill', 'Error !');
      return;
    }

    const machineObj = {
      machine_id: this.assignMachineForm.value.machine_id,
      user_id: this.operatorId,
      time_slots: this.time_slots,
      days: this.day_ids
    };
    console.log(machineObj);

    this.machinesService.assignMachine(machineObj).subscribe((res) => {
      if (res.status === 'success') {
        this.activeModal.close('assigned');
        this.toastr.success('Machine assigned successfully!', 'Success !');
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }
}
