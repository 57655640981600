import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from './../services/jwt.service';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable()
export class ReqInterceptor implements HttpInterceptor {

    setHeaders() {
        const token = this._jwtService.getToken();
        const phone = this._localStorageService.getItem('phone');

        const params = {};
        if (token) {
            params['X-USER-TOKEN'] = token ? token : null;
            params['X-USER-PHONE-NUMBER'] = phone ? phone : null;
            const headers: HttpHeaders = new HttpHeaders(params);
            return headers;
        }
        return;
    }

    constructor(
        private _jwtService: JwtService,
        private _localStorageService: LocalStorageService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.search('/api/v1/users/sign_in') !== -1) {
            return next.handle(req);
        } else {
            const modifiedReq = req.clone({ headers: this.setHeaders() });
            return next.handle(modifiedReq);
        }

    }
}
