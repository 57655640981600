export const EVENING_SLOTS = [{ value: '04:00 PM - 04:30 PM', id: 1, format: '16:00-16:30' },
{ value: '04:30 PM - 05:00 PM', id: 2, format: '16:30-17:00' },
{ value: '05:00 PM - 05:30 PM', id: 3, format: '17:00-17:30' },
{ value: '05:30 PM - 06:00 PM', id: 4, format: '17:30-18:00' },
{ value: '06:00 PM - 06:30 PM', id: 5, format: '18:00-18:30' },
{ value: '06:30 PM - 07:00 PM', id: 6, format: '18:30-19:00' },
{ value: '07:00 PM - 07:30 PM', id: 7, format: '19:00-19:30' },
{ value: '07:30 PM - 08:00 PM', id: 8, format: '19:30-20:00' },
{ value: '08:00 PM - 08:30 PM', id: 9, format: '20:00-20:30' },
{ value: '08:30 PM - 09:00 PM', id: 10, format: '20:30-21:00' },
{ value: '09:00 PM - 09:30 PM', id: 11, format: '21:00-21:30' },
{ value: '09:30 PM - 10:00 PM', id: 12, format: '21:30-22:00' },
{ value: '10:00 PM - 10:30 PM', id: 13, format: '22:00-22:30' },
{ value: '10:30 PM - 11:00 PM', id: 14, format: '22:30-23:00' },
{ value: '11:00 PM - 11:30 PM', id: 15, format: '23:00-23:30' },
{ value: '11:30 PM - 12:00 AM', id: 16, format: '23:30-00:00' }];