export const NIGHT_SLOTS = [{ value: '12:00 AM - 12:30 AM', id: 1, format: '00:00-00:30' },
{ value: '12:30 AM - 01:00 AM', id: 2, format: '00:30-01:00' },
{ value: '01:00 AM - 01:30 AM', id: 3, format: '01:00-01:30' },
{ value: '01:30 AM - 02:00 AM', id: 4, format: '01:30-02:00' },
{ value: '02:00 AM - 02:30 AM', id: 5, format: '02:00-02:30' },
{ value: '02:30 AM - 03:00 AM', id: 6, format: '02:30-03:00' },
{ value: '03:00 AM - 03:30 AM', id: 7, format: '03:00-03:30' },
{ value: '03:30 AM - 04:00 AM', id: 8, format: '03:30-04:00' },
{ value: '04:00 AM - 04:30 AM', id: 9, format: '04:00-04:30' },
{ value: '04:30 AM - 05:00 AM', id: 10, format: '04:30-05:00' },
{ value: '05:00 AM - 05:30 AM', id: 11, format: '05:00-05:30' },
{ value: '05:30 AM - 06:00 AM', id: 12, format: '05:30-06:00' },
{ value: '06:00 AM - 06:30 AM', id: 13, format: '06:00-06:30' },
{ value: '06:30 AM - 07:00 AM', id: 14, format: '06:30-07:00' },
{ value: '07:00 AM - 07:30 AM', id: 15, format: '07:00-07:30' },
{ value: '07:30 AM - 08:00 AM', id: 16, format: '07:30-08:00' }];
