import { API_ENDPOINTS } from './../constants/api-endpoints.constant';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {

  constructor(private apiService: ApiService) { }

  login(loginObj) {
    return this.apiService.post(API_ENDPOINTS.LOGIN, loginObj);
  }

  sendOTP(obj) {
    return this.apiService.post(API_ENDPOINTS.SEND_OTP, obj);
  }

  verifyOTP(obj) {
    return this.apiService.post(API_ENDPOINTS.VERIFY_OTP, obj);
  }

  resetPassword(obj) {
    return this.apiService.put(API_ENDPOINTS.RESET_PASSWORD, obj);
  }

}
