export const REGEX_PATTERNS = {
    EMAIL: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    PASSWORD: /^[a-zA-Z0-9]{6,}$/,
    NAME: /^(?! )[A-Za-z ]*(?<! )$/,
    PRODUCT_NAME: /[a-zA-Z0-9!@#$&()\\-`.+,/\"]*/,
    REFILLS: /^[1-3]{1,1}$/,
    KEY_NUMBER: /^[a-zA-Z0-9-]+$/,
    MACHINE_NUMBER: /^[a-zA-Z0-9_\\s ]*$/,
    CUP_NAME: /[a-zA-Z0-9!@#$&()\\-`.+,/\"]*/,
    CLIENT_FIRM: /^[a-zA-Z0-9-. ]+$/,
    PRICE: /^\d+(?:[.,]\d+)*$/,
    QUANTITY: /(?!0+$)[0-9]{1,10}$/,
    ONLY_NUMBERS: /^-?[0-9+]+$/,
    ONLY_POSITIVE: /^[0-9]\d*$/
    // QUANTITY: /^[0-9]{1,10}$/
    // PASSWORD: /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*_()#^?&])[A-Za-z\d@$!%*_()#^?&]{8,}$/,
};

