import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-errors-modal',
  templateUrl: './errors-modal.component.html',
  styleUrls: ['./errors-modal.component.scss']
})
export class ErrorsModalComponent implements OnInit {

  @Input() message: number;

  constructor(private activeModal: NgbActiveModal,
   ) { }

  ngOnInit() {
  }

  closeDialog() {
    this.activeModal.dismiss('dismissed');
  }

}
