import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';

@Injectable()
export class NotificationsService {

  constructor(private apiService: ApiService) { }

  getTickets(cityId, status, type) {
    const params = {
      city_id: cityId || null,
      status: status || null,
      type: type || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.TICKETS}?is_resolved=false${url}`);
  }


  getResolvedTickets(cityId, type) {
    if (type && cityId) {
      return this.apiService.get(`${API_ENDPOINTS.TICKETS}?is_resolved=true&city_id=${cityId}&type=${type}`);
    } else if (cityId) {
      return this.apiService.get(`${API_ENDPOINTS.TICKETS}?is_resolved=true&city_id=${cityId}`);
    } else if (type) {
      return this.apiService.get(`${API_ENDPOINTS.TICKETS}?is_resolved=true&type=${type}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.TICKETS}?is_resolved=true`);
    }
  }

  getNotifications(type, cityId) {
    if (type && cityId) {
      return this.apiService.get(`${API_ENDPOINTS.NOTIFICATIONS}?city_id=${cityId}&type=${type}`);
    } else if (cityId) {
      return this.apiService.get(`${API_ENDPOINTS.NOTIFICATIONS}?city_id=${cityId}`);
    } else if (type) {
      return this.apiService.get(`${API_ENDPOINTS.NOTIFICATIONS}?type=${type}`);
    } else {
      return this.apiService.get(API_ENDPOINTS.NOTIFICATIONS);
    }
  }

  searchUser(searchTerm, machineId) {
    return this.apiService.get(`${API_ENDPOINTS.SEARCH_USER}?search_term=${searchTerm}&machine_id=${machineId}`);
  }

  updateTicket(ticketId, ticketObj) {
    return this.apiService.put(`${API_ENDPOINTS.TICKETS}/${ticketId}`, ticketObj);
  }

}
