import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-delete-assign-machine',
  templateUrl: './delete-assign-machine.component.html',
  styleUrls: ['./delete-assign-machine.component.scss']
})
export class DeleteAssignMachineComponent implements OnInit {

  @Input() schedule;

  constructor(private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private userService: UserService) { }

  ngOnInit() {
    console.log(this.schedule);
  }

  closeDialog() {
    this.activeModal.dismiss('dismissed');
  }

  deleteSchedule() {
    this.userService.deleteSchedule(this.schedule.id).subscribe((res) => {
      if (res.status === 'success') {
        this.activeModal.close('deleted');
        // this.router.navigate(['/operators']);
        this.toastr.success('Schedule deleted successfully!', 'Success !');
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

}
