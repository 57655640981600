import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class MachinesService {

  constructor(private apiService: ApiService) { }

  getAllMachines(cityId, client_status, searchTerm, title, sort, status, type, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      status: status || null,
      client_status: client_status || null,
      type: type || null,
      search_term: searchTerm || null,
    };
    if(params.client_status === 'all'){
      params.client_status = null;
    }
    if(params.city_id === 'all'){
      params.city_id = null;
    }

    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.MACHINES}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
    // return this.apiService.get(`${API_ENDPOINTS.MACHINES}?allow_inactive_clients=true&sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  getMachinesReport(cityId, client_status, searchTerm, title, sort, status, type){
    const params = {
      city_id: cityId || null,
      status: status || null,
      client_status: client_status || null,
      type: type || null,
      search_term: searchTerm || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.MACHINES}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  getMachinesByCity(cityId) {
    return this.apiService.get(`${API_ENDPOINTS.MACHINES}?city_id=${cityId}`);
  }

  getMachinesByClient(clientId, cityId, base_clientId?) {
    const params = {
      city_id: cityId || null,
      client_id: clientId || null,
      base_client_id: base_clientId || null,
    };

    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?${url}`);

    // let city_id = cityId === 'all' ? null : cityId;
    // let client_id = clientId === 'all' ? null : clientId;
    // let base_Id = base_clientId ? base_clientId : null;
    // if (city_id && client_id) {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?client_id=${client_id}&city_id=${city_id}`);
    // } else if (city_id) {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?city_id=${city_id}`);
    // } else if (client_id) {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?client_id=${client_id}`);
    // } else {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}`);
    // }
  }
  
  getMachinesNamesByClient(clientId, cityId, base_clientId?) {

    const params = {
      city_id: cityId || null,
      client_id: clientId || null,
      base_client_id: base_clientId || null,
    };

    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_NAMES_BY_CLIENT}?${url}`);

    // let city_id = cityId === 'all' ? null : cityId;
    // let client_id = clientId === 'all' ? null : clientId;
    // let base_Id = base_clientId ? base_clientId : null;
    // if (city_id && client_id) {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?client_id=${client_id}&city_id=${city_id}`);
    // } else if (city_id) {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?city_id=${city_id}`);
    // } else if (client_id) {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?client_id=${client_id}`);
    // } else {
    //   return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}`);
    // }
  }

  getMachinesByCityId(cityId, type) {
    if (type && cityId && cityId !== 'all') {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?machine_type=${type}&city_id=${cityId}`);
    } else if (cityId && cityId !== 'all') {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?city_id=${cityId}`);
    } else if (type && cityId !== 'all') {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?machine_type=${type}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}`);
    }
  }


  getMachinesByType(city_id, client_id, type) {
    if (city_id && client_id) {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?client_id=${client_id}&city_id=${city_id}&machine_type=${type}`);
    } else if (city_id) {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?city_id=${city_id}&machine_type=${type}`);
    } else if (client_id) {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?client_id=${client_id}&machine_type=${type}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?machine_type=${type}`);
    }
  }

  getMachinesByOp(op_id) {
    return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?op_id=${op_id}`);
  }

  getCupboardsByClient(client_id) {
    return this.apiService.get(`${API_ENDPOINTS.GET_CUPS_BY_CLIENT}?client_id=${client_id}`);
  }

  getMachineConfigById(id) {
    return this.apiService.get(`${API_ENDPOINTS.MACHINE_CONFIG_BY_ID}?registration_id=${id}`);
  }

  getMachineById(machineId) {
    return this.apiService.get(`${API_ENDPOINTS.MACHINES}/${machineId}`);
  }

  getCashDetails(machineId) {
    return this.apiService.get(`${API_ENDPOINTS.LAST_REFILL_AND_CASH_COLLECTED}?machine_id=${machineId}`);
  }

  getOpOrMachineWiseCash(type) {
    return this.apiService.get(`${API_ENDPOINTS.CASH_UPDATE_REPORT}?type=${type}`);
  }

  getMachineWiseStock() {
    return this.apiService.get(`${API_ENDPOINTS.MACHINE_STOCK_REPORT}`);
  }

  downloadTemplate(machineId) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.MACHINE_TEMPLATE}?machine_id=${machineId}`, httpOptions);
  }

  getMachineCashOperations(Id) {
    return this.apiService.get(`${API_ENDPOINTS.MACHINE_CASH_DETAILS}?machine_id=${Id}`);
  }

  getMachibeByOpId(id) {
    return this.apiService.get(`${API_ENDPOINTS.MACHINES_BY_CITY}?operator_id=${id}`);
  }

  getSchedulesByDays(day_ids, userId, machineId) {
    // let url = '';
    // for (const value of day_ids) {
    //   if (value) {
    //     url = `${value},`;
    //   }
    // }
    let url = day_ids.join(',');
    if (machineId) {
      return this.apiService.get(`${API_ENDPOINTS.MACHINE_SCHEDULES}?user_id=${userId}&machine_id=${machineId}&days=${url}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.MACHINE_SCHEDULES}?user_id=${userId}&days=${url}`);
    }
  }

  uploadMachines(formData: FormData, cityId) {
    let url;
    if (cityId) {
      url = `import_machine_excel_sheet?city_id=${cityId}`;
    } else {
      url = 'import_machine_excel_sheet';
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const httpOptions = { headers };
    return this.apiService.post(url, formData, httpOptions);
  }

  UploadStock(formData: FormData) {
    let url;
    // if (cityId) {
    //   url = `import_machines_stocks?city_id=${cityId}`;
    // } else {
    url = 'import_machines_stocks';
    // }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const httpOptions = { headers };
    return this.apiService.post(url, formData, httpOptions);
  }

  createMachine(machineObj) {
    return this.apiService.post(API_ENDPOINTS.CREATEMACHINE, machineObj);
  }

  returnQty(data) {
    return this.apiService.post(API_ENDPOINTS.RETURN_PRODUCT_CP, data);
  }

  addMachineConfig(machineObj) {
    return this.apiService.post(API_ENDPOINTS.MACHINE_CONFIG, machineObj);
  }

  addProdConfig(prodObj) {
    return this.apiService.put(API_ENDPOINTS.PRODUCT_CONFIG, prodObj);
  }

  editMachine(machineObj, machineId) {
    return this.apiService.put(`${API_ENDPOINTS.CREATEMACHINE}/${machineId}`, machineObj);
  }

  deleteMachine(machineId) {
    return this.apiService.delete(`${API_ENDPOINTS.MACHINES}/${machineId}`);
  }

  removeOperator(machineId, opId) {
    return this.apiService.post(`${API_ENDPOINTS.REMOVE_OPERATOR}?machine_id=${machineId}&user_id=${opId}`);
  }

  updateMachine(machineId, machineObj) {
    return this.apiService.put(`${API_ENDPOINTS.MACHINES}/${machineId}`, machineObj);
  }

  updateCashInfo(Obj) {
    return this.apiService.post(`${API_ENDPOINTS.UPDATE_CASH_INFO}`, Obj);
  }

  updateMachineStock(Obj) {
    return this.apiService.post(`${API_ENDPOINTS.UPDATE_MACHINE_STOCK}`, Obj);
  }

  assignUserToMachine(userId, machineId) {
    return this.apiService.get(`${API_ENDPOINTS.MACHINES}/${machineId}/${API_ENDPOINTS.ASSIGNUSER}?user_id=${userId}`);
  }

  searchMachine(searchTerm, currentPage, itemPerPage) {
    return this.apiService.get(`${API_ENDPOINTS.SEARCHMACHINE}?search_term=${searchTerm}&page=${currentPage}&per_page=${itemPerPage}`);
  }

  assignMachine(machineObj) {
    return this.apiService.post(`${API_ENDPOINTS.ASSIGN_MACHINE}`, machineObj);
  }

  editAssignMachine(scheduleId, machineObj, filter) {
    return this.apiService.put(`${API_ENDPOINTS.ASSIGN_MACHINE}/${scheduleId}?day=${filter}`, machineObj);
  }

  editAssignUser(machineObj) {
    return this.apiService.post(`${API_ENDPOINTS.ASSIGN_USER}`, machineObj);
  }

  addProduct(productObj) {
    return this.apiService.post(API_ENDPOINTS.ADD_PRODUCT_TO_MACHINE, productObj);
  }

  addSoftConfig(Obj) {
    return this.apiService.post(API_ENDPOINTS.SOFTWARE_UPDATE, Obj);
  }

  getMachineDetailsByStatus(city_id, status) {
    if (city_id && status) {
      return this.apiService.get(`${API_ENDPOINTS.MACHINE_BY_STATUS}?status=${status}&city_id=${city_id}`);
    } else if (city_id) {
      return this.apiService.get(`${API_ENDPOINTS.MACHINE_BY_STATUS}?city_id=${city_id}`);
    } else if (status) {
      return this.apiService.get(`${API_ENDPOINTS.MACHINE_BY_STATUS}?status=${status}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.MACHINE_BY_STATUS}`);
    }
  }

  checkMachineAttrValidity(param, value) {
    if (param === 'machine_number') {
      return this.apiService.get(`${API_ENDPOINTS.VALIDATE_MACHINE_ATTR}?machine_number=${value}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.VALIDATE_MACHINE_ATTR}?key_number=${value}`);
    }
  }

  getProductById(id) {
    return this.apiService.get(`${API_ENDPOINTS.PRODUCT_DETAILS}?product_id=${id}`);
  }

}
