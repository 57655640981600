import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { ExportAsModule } from 'ngx-export-as';
import { AgmCoreModule } from '@agm/core';

import { ReqInterceptor } from './core/interceptors/req-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './core/formatters/date.parser';
import { RoleGuard } from './shared/guard/role.guard';

import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true
    }),
    BrowserAnimationsModule,
    SharedModule,
    ExportAsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyD2LCb4u8zz-VfeI7aiNf3PBhhQUHXn3u8",
      libraries: ["places"]
    }),
    NgxChartsModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: ReqInterceptor, multi: true
  },
  {
    provide: NgbDateParserFormatter,
    useClass: NgbDateCustomParserFormatter
  },
    RoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

