import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ExportAsService } from 'ngx-export-as';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { MachinesService } from 'src/app/core/services/machines.service';

@Component({
  selector: 'app-category-wise-summary',
  templateUrl: './category-wise-summary.component.html',
  styleUrls: ['./category-wise-summary.component.scss']
})
export class CategoryWiseSummaryComponent implements OnInit {

  user_role: any;
  city_id: any;
  pending: any;
  submitted: any;
  not_submitted;
  userId: any = null;
  sp_pending: any;
  sp_submitted: any;
  sp_not_submitted;
  step: any = 1;

  constructor(private userService: UserService,
    private toastr: ToastrService,
    private exportAsService: ExportAsService,
    private localStorageService: LocalStorageService,
    private machinesService: MachinesService,
    private router: Router
  ) {
    this.user_role = this.localStorageService.getItem('role');
    if (this.user_role.user_role_name === 'operator_manager') {
      this.city_id = this.localStorageService.getItem('city_id');
    }
  }

  ngOnInit() {
    this.getCatWiseCashSummary();
  }

  getCatWiseCashSummary() {
    this.userService.getCatWiseCashSummary().subscribe((res) => {
      if (res.status === 'success') {
        this.pending = res.operator_manager.pending_for_collection[0];
        this.submitted = res.operator_manager.collected_and_submitted[0];
        this.not_submitted = res.operator_manager.collected_but_not_submitted[0];

      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }
  // (click)="setStep(2,'single',item?.id)"
  setStep(step, type?, id?) {
    this.step = step;
    this.userId = id;
    if (type && id) {
      this.getCatWiseSummaryOpbySp(id);
    } else if (type) {
      switch (type) {
        case 'om':
          this.getCatWiseCashSummary();
          break;
        case 'sp':
          // this.getCatWiseSummarySup();
          break;
        default:
          break;
      }
    }
  }

  getCatWiseSummarySup() {
    this.userService.getCatWiseSummarySup().subscribe((res) => {
      if (res.status === 'success') {
        this.sp_pending = res.operator_manager.pending_for_collection[0];
        this.sp_submitted = res.operator_manager.collected_and_submitted[0];
        this.sp_not_submitted = res.operator_manager.collected_but_not_submitted[0];
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  getCatWiseSummaryOpbySp(id) {
    this.userService.getCatWiseSummaryOpbySp(id).subscribe((res) => {
      if (res.status === 'success') {
        this.sp_pending = res.supervisor.pending_for_collection[0];
        this.sp_submitted = res.supervisor.collected_and_submitted[0];
        this.sp_not_submitted = res.supervisor.collected_but_not_submitted[0];
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }
}
