import { FULL_DAY_SLOTS } from './../../../core/constants/full-day-slots.constants';
import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { REGEX_PATTERNS } from 'src/app/core/constants/regex-patterns.constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { MachinesService } from 'src/app/core/services/machines.service';
import { ClientService } from 'src/app/core/services/client.service';
// import { TIME_SLOTS } from 'src/app/core/constants/time-slots.constants';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Days } from 'src/app/core/enums/days.enum';
import { MORNING_SLOTS } from 'src/app/core/constants/morning-slots.constants';
import { EVENING_SLOTS } from 'src/app/core/constants/evening-slots.constants';
import { NIGHT_SLOTS } from 'src/app/core/constants/night-slots';

@Component({
  selector: 'app-edit-assign-machine-to-user',
  templateUrl: './edit-assign-machine-to-user.component.html',
  styleUrls: ['./edit-assign-machine-to-user.component.scss']
})
export class EditAssignMachineToUserComponent implements OnInit {

  @Input() operatorId;
  @Input() schedule;
  @Input() filter;
  @Input() supId;
  assignMachineForm: any;
  clients: any;
  machines: any;
  cities: any;
  cityId: any;
  client_id: any;
  // timeSlots: any = TIME_SLOTS;
  currentTime: any;
  day_ids: any = [];
  time_slots: any = [];
  schedules: any;
  machineId: any;
  currentDay: any;
  fulldaySlots: any = FULL_DAY_SLOTS;
  span_id: any;
  // spans: any = [{ id: 1, title: 'Before Noon' }, { id: 2, title: 'After Noon' }];
  // spanId: any;
  // sId: number = 1;
  slot: any = '';
  m_slotArray: any = [];
  e_slotArray: any = [];
  n_slotArray: any = [];
  morning_cnt = 0;
  evening_cnt = 0;
  night_cnt = 0;
  morningSlots: any = [...MORNING_SLOTS];
  eveningSlots: any = [...EVENING_SLOTS];
  nightSlots: any = [...NIGHT_SLOTS];
  timeSlots: any = [...MORNING_SLOTS];
  formattedSlots: any = [];

  constructor(private activeModal: NgbActiveModal,
    private userService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private machinesService: MachinesService,
    private clientService: ClientService) {
    this.morningSlots = [...MORNING_SLOTS];
    this.eveningSlots = [...EVENING_SLOTS];
    this.nightSlots = [...NIGHT_SLOTS];
  }

  ngOnInit() {
    this.setFormValues();
    this.slot = this.schedule.schedules[0].time;
    // console.log(this.slot);
    this.setSlotsByTime(this.slot);
    this.formattedSlots = this.formatSlots(this.schedule.schedules);
    this.formattedSlots.forEach(item => {
      item = item.split('-');
      this.time_slots.push(item);
    });
    this.setSlotsByTimeOne(this.slot);
    this.initializeForm();
    this.getCities();
    this.getClients();
  }

  setSlotsByTimeOne(slot) {
    this.slot = slot;
    // this.slotArray = [];
    if (this.morning_cnt === 0 && this.evening_cnt === 0 && this.night_cnt === 0) {
      switch (slot) {
        case 'morning':
          for (let i = 0; i < this.timeSlots.length; i++) {
            let item = this.timeSlots[i];
            for (let j = 0; j < this.formattedSlots.length; j++) {
              let formatedSlot = this.formattedSlots[j];
              if (item.format === formatedSlot) {
                this.m_slotArray.push({
                  value: item.format,
                  format: item.format,
                  display: item.value,
                  id: item.id
                });
                this.m_slotArray = this.uniqueArray(this.m_slotArray);
              }
            }
          }
          break;
        case 'evening':
          for (let i = 0; i < this.timeSlots.length; i++) {
            let item = this.timeSlots[i];
            for (let j = 0; j < this.formattedSlots.length; j++) {
              let formatedSlot = this.formattedSlots[j];
              if (item.format === formatedSlot) {
                this.e_slotArray.push({
                  value: item.format,
                  format: item.format,
                  display: item.value,
                  id: item.id
                });
                this.e_slotArray = this.uniqueArray(this.e_slotArray);
              }
            }
          }
          break;
        case 'night':
          for (let i = 0; i < this.timeSlots.length; i++) {
            let item = this.timeSlots[i];
            for (let j = 0; j < this.formattedSlots.length; j++) {
              let formatedSlot = this.formattedSlots[j];
              if (item.format === formatedSlot) {
                this.n_slotArray.push({
                  value: item.format,
                  format: item.format,
                  display: item.value,
                  id: item.id
                });
                this.n_slotArray = this.uniqueArray(this.n_slotArray);
              }
            }
          }
          break;
        default:
          break;
      }
    }
    this.getSchedulesByDays();
  }

  setCounter(slot) {
    switch (slot) {
      case 'morning':
        this.morning_cnt = 1;
        break;
      case 'evening':
        this.evening_cnt = 1;
        break;
      case 'night':
        this.night_cnt = 1;
        break;
      default:
        break;
    }
  }

  uniqueArray(array) {
    let resultArray = _.uniqBy(array, 'format');
    return resultArray;
  }

  formatSlots(schedules) {
    let scheduleArray = [];
    for (let i = 0; i < schedules.length; i++) {
      let schedule = schedules[i];
      let start = moment(schedule.start_time).format('HH:mm');
      let end = moment(schedule.end_time).format('HH:mm');
      let slot = `${start}-${end}`;
      scheduleArray.push(slot);
    }
    return scheduleArray;
  }

  setFormValues() {
    const { city_id, client_id } = this.schedule.machine[0];
    // const { start_time, end_time } = this.schedule.schedules[0];
    // const start = moment(start_time).format('HH:mm:ss');
    // const end = moment(end_time).format('HH:mm:ss');
    // const scheduleTime = [`${start}-${end}`];
    // this.currentTime = this.getTimeSlotsfrom24To12Format(scheduleTime);
    this.cityId = city_id;
    this.client_id = client_id;
    const today = moment().format('dddd');
    this.currentDay = [today];
    this.day_ids.push(Days[today]);
  }

  // getTimeSlotsfrom24To12Format(format) {
  //   let currentSlot;
  //   for (let i = 0; i < this.fulldaySlots.length; i++) {
  //     let slot = this.fulldaySlots[i].format;
  //     if (slot === format[0]) {
  //       let id = this.fulldaySlots[i].id;
  //       this.span_id = (id >= 0 && id <= 24) ? 1 : 2;
  //       this.setSlotsByMeridian(this.span_id);
  //       currentSlot = this.fulldaySlots[i].value;
  //     }
  //   }
  //   return new Array(currentSlot);
  // }

  onTimeAdd(item, slot) {
    switch (slot) {
      case 'morning':
        if (this.e_slotArray.length === 1 || this.n_slotArray.length === 1) {
          this.m_slotArray = [];
          this.toastr.error('You can select only one slot', 'Error !');
        } else {
          this.addValue(item, slot);
        }
        break;
      case 'evening':
        if (this.m_slotArray.length === 1 || this.n_slotArray.length === 1) {
          this.e_slotArray = [];
          this.toastr.error('You can select only one slot', 'Error !');
        } else {
          this.addValue(item, slot);
        }
        break;
      case 'night':
        if (this.e_slotArray.length === 1 || this.m_slotArray.length === 1) {
          this.n_slotArray = [];
          this.toastr.error('You can select only one slot', 'Error !');
        } else {
          this.addValue(item, slot);
        }
        break;
      default:
        break;
    }
  }

  addValue(item, slot) {
    this.setCounter(slot);
    const slots = item.format.split('-');
    this.time_slots.push(slots);
    this.assignMachineForm.controls['time_slot'].clearValidators();
    this.assignMachineForm.controls['time_slot'].updateValueAndValidity();
  }

  validateTimeslot() {
    if (!this.time_slots.length || !this.currentTime) {
      this.assignMachineForm.controls['time_slot'].setValidators([Validators.required]);
      this.assignMachineForm.controls['time_slot'].markAsTouched();
      this.assignMachineForm.controls['time_slot'].updateValueAndValidity();
    }
  }

  onTimeRemove(item, slot) {
    this.setCounter(slot);
    this.getSchedulesByDays();
    // if (this.currentTime.length) {
    //   this.currentTime = [];
    // }
    this.time_slots = [];
    // if (this.time_slots.length) {
    //   const value = item.format;
    //   for (let i = 0; i < this.time_slots.length; i++) {
    //     const slot = this.time_slots[i].join('-');
    //     if (slot === value) {
    //       this.time_slots.splice(i, 1);
    //     }
    //   }
    // }
    this.assignMachineForm.controls['time_slot'].setValue(this.time_slots.length ? this.time_slots[0] : '', {
      onlySelf: true
    });
    this.validateTimeslot();
  }

  setSlotsByTime(slot) {
    this.slot = slot;
    // this.time_slots = [];
    switch (slot) {
      case 'morning':
        this.timeSlots = [...this.morningSlots];
        this.setSlotsByTimeOne(this.slot);
        break;
      case 'evening':
        this.timeSlots = [...this.eveningSlots];
        this.setSlotsByTimeOne(this.slot);
        break;
      case 'night':
        this.timeSlots = [...this.nightSlots];
        this.setSlotsByTimeOne(this.slot);
        break;
      default:
        break;
    }
    // this.getSchedulesByDays();
  }

  closeDialog() {
    this.activeModal.dismiss('dismissed');
  }

  initializeForm() {
    const { city_id, client_id, id } = this.schedule.machine[0];
    this.assignMachineForm = this.fb.group({
      city_id: [city_id, [Validators.required]],
      client_id: [client_id, [Validators.required]],
      // span_id: [this.span_id, [Validators.required]],
      machine_id: [id, [Validators.required]],
      time_slot: [''],
      // time_slot2: [''],
      // time_slot3: ['']
    });
  }

  getSchedulesByDays() {
    this.machinesService.getSchedulesByDays(this.day_ids, this.operatorId, this.schedule.machine[0].id).subscribe((res) => {
      if (res.status === 'success') {
        this.schedules = res.scheduled_time_slots;
        if (this.schedules.length) {
          this.removeAllocatedTimeSlots();
        }
      } else {
        // this.setSlotsByMeridian(this.spanId);
      }
    }, (err) => {
      console.log(err);
    });
  }

  removeAllocatedTimeSlots() {
    if (this.timeSlots.length) {
      for (let i = 0; i < this.timeSlots.length; i++) {
        for (let j = 0; j < this.schedules.length; j++) {
          if (this.schedules[j] === this.timeSlots[i].format) {
            this.timeSlots.splice(i, 1);
            i = 0;
            j = 0;
          }
        }
      }
    }
  }

  // preventSelectingMultipleSlot() {

  // }

  // changeSpan(e) {
  //   this.spanId = e.target.value;
  //   this.currentTime = [];
  //   this.time_slots = [];
  //   this.setSlotsByMeridian(this.spanId);
  //   this.assignMachineForm.controls['span_id'].setValue(this.spanId, {
  //     onlySelf: true
  //   });
  //   this.getSchedulesByDays();
  // }

  // setSlotsByMeridian(spanId) {
  //   this.sId = +spanId;
  //   this.timeSlots = [];
  //   if (this.sId === 1) {
  //     this.timeSlots = [...this.amSlots];
  //   } else {
  //     this.timeSlots = [...this.pmSlots];
  //   }
  // }

  // removeAllocatedTimeSlots() {
  //   if (this.timeSlots.length) {
  //     for (let i = 0; i < this.timeSlots.length; i++) {
  //       for (let j = 0; j < this.schedules.length; j++) {
  //         if (this.schedules[j] === this.timeSlots[i].value) {
  //           this.timeSlots.splice(i, 1);
  //         }
  //       }
  //     }
  //   }
  // }

  getCities() {
    this.userService.getCities().subscribe((res) => {
      if (res.status === 'success') {
        this.cities = res.cities;
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  changeCity(e) {
    this.cityId = e.target.value;
    this.assignMachineForm.controls['city_id'].setValue(this.cityId, {
      onlySelf: true
    });
    this.getClients();
  }

  getClients() {
    this.clientService.getClientsByCity(this.cityId).subscribe((res) => {
      if (res.status === 'success') {
        this.clients = res.client_details;
        this.getMachinesByClient();
      } else {
        console.log(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  getMachinesByClient() {
    this.userService.getMachinesByClient(this.client_id, this.supId, true).subscribe((res) => {
      if (res.status === 'success') {
        this.machines = res.machines;
        // this.getSchedulesByDays();
        // this.initializeForm();
      } else {
        console.log(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  changeClient(e) {
    this.client_id = e.target.value;
    this.assignMachineForm.controls['client_id'].setValue(this.client_id, {
      onlySelf: true
    });
    this.setMachines();
  }

  changeMachine(e) {
    this.machineId = e.target.value;
    this.assignMachineForm.controls['machine_id'].setValue(this.machineId, {
      onlySelf: true
    });
  }

  setMachines() {
    this.getMachinesByClient();
  }

  onSubmit() {
    const machineObj = {
      machine_id: this.assignMachineForm.value.machine_id,
      user_id: this.operatorId,
      time_slots: this.time_slots ? this.time_slots : this.m_slotArray.length,
      days: this.day_ids
    };
    const { schedules: schedule } = this.schedule;
    this.machinesService.editAssignMachine(schedule[0].id, machineObj, this.filter).subscribe((res) => {
      if (res.status === 'success') {
        this.activeModal.close('assigned');
        this.toastr.success('Machine schedule edited successfully!', 'Success !');
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

}
