import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { forkJoin } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserService {

  constructor(private apiService: ApiService) { }

  getOperators(cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.OPERATORS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }


  getOperatorReport(cityId, searchTerm, title, sort){
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.OPERATORS}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  editOpManager(id){
    return this.apiService.get(`${API_ENDPOINTS.USERS}/${id}/${API_ENDPOINTS.EDIT}`);
  }

  getReports(userId?) {
    if (userId) {
      return this.apiService.get(`${API_ENDPOINTS.REPORTS}?base_client_id=${userId}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.REPORTS}`);
    }
  }

  getOperatorBySupervisor(supervisorId) {
    return this.apiService.get(`${API_ENDPOINTS.OPERATOR_BY_SUPERVISOR}?id=${supervisorId}&available_status=true`);
  }

  getOperatorBySup(supervisorId) {
    return this.apiService.get(`${API_ENDPOINTS.OPERATOR_BY_SUPERVISOR}?id=${supervisorId}`);
  }

  getOperatorsByCity(city_id) {
    if (city_id) {
      return this.apiService.get(`${API_ENDPOINTS.OPERATORS}?city_id=${city_id}&sort_title=name&sort_type=asc`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.OPERATORS}?sort_title=name&sort_type=asc`);
    }
  }

  getOpManagersByCity(city_id) {
    return this.apiService.get(`${API_ENDPOINTS.OP_MANAGER_BY_CITY}?city_id=${city_id}`);
  }

  getOperationManagers(cityId, searchTerm, title, sort) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.OPERATIONMANAGERS}?sort_title=${title}&sort_type=${sort}${url}`);
  }

  getOperationReport(cityId, searchTerm, title, sort){
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.OPERATIONMANAGERS}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);

  }

  getAccManagers(cityId, searchTerm, title, sort) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.ACCOUNT_MANAGER}?sort_title=${title}&sort_type=${sort}${url}`);
  }

  getAccManagersReport(cityId, searchTerm, title, sort){
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.ACCOUNT_MANAGER}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  getTechnicians(cityId, searchTerm, title, sort) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.TECHNICIAN}?sort_title=${title}&sort_type=${sort}${url}`);
  }

  getManufacturers() {
    return this.apiService.get(API_ENDPOINTS.MANUFACTURERS);
  }

  getTemplates() {
    return this.apiService.get(API_ENDPOINTS.TEMPLATES);
  }

  getCities(user_id?) {
    if (user_id) {
      return this.apiService.get(`${API_ENDPOINTS.CITIES}?base_client_id=${user_id}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.CITIES}`);
    }
  }

  searchCity(search) {
    return this.apiService.get(`${API_ENDPOINTS.CITIES}?search_term=${search}`);
  }

  searchCat(search) {
    return this.apiService.get(`${API_ENDPOINTS.CATEGORIES}?search_term=${search}`);
  }

  searchBrand(search) {
    return this.apiService.get(`${API_ENDPOINTS.BRANDS}?search_term=${search}`);
  }

  getOperatorDetails(operatorId, frequency) {
    return this.apiService.get(`${API_ENDPOINTS.USERS}/${operatorId}?day=${frequency}`);
  }

  getOperatorDetailsById(operatorId) {
    return this.apiService.get(`${API_ENDPOINTS.USERS}/${operatorId}`);
  }

  getSupervisorDetails(supId, frequency) {
    return this.apiService.get(`${API_ENDPOINTS.USERS}/${supId}?day=${frequency}`);
  }

  editUser(userId, userObj) {
    return this.apiService.put(`${API_ENDPOINTS.USERS}/${userId}`, userObj);
  }

  changeStatus(userObj) {
    return this.apiService.post(`${API_ENDPOINTS.UPDATE_WORK_STATUS}`, userObj);
  }

  changeClientStatus(data) {
    const { id, status } = data;
    return this.apiService.post(`${API_ENDPOINTS.UPDATE_CLIENT_STATUS}?id=${id}&status=${status}`);
  }

  confirmCashSubmission(cashDetailObj) {
    return this.apiService.post(`${API_ENDPOINTS.VALIDATE_CASH_SUBMISSION}`, cashDetailObj);
  }

  addUser(userObj) {
    return this.apiService.post(`${API_ENDPOINTS.USERS}`, userObj);
  }

  deleteUser(userId) {
    return this.apiService.delete(`${API_ENDPOINTS.USERS}/${userId}`);
  }

  removeLeave(userId, leaveId) {
    return this.apiService.post(`${API_ENDPOINTS.REMOVE_LEAVE}?user_id=${userId}&leave_id=${leaveId}`);
  }

  getSupervisorsByOPManager(opManagerId?) {
    return this.apiService.get(`${API_ENDPOINTS.SUPERVISOR_BY_OPMANAGER}?id=${opManagerId}`);
  }

  getSupervisorsByCity(cityId) {
    if (cityId) {
      return this.apiService.get(`${API_ENDPOINTS.SUPERVISORS}?city_id=${cityId}&sort_title=name&sort_type=asc`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.SUPERVISORS}?sort_title=name&sort_type=asc`);
    }
  }

  getSupervisors(cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.SUPERVISORS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  getSupervisorsReport(cityId, searchTerm, title, sort){
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.SUPERVISORS}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  getSupervisorByCity(cityId) {
    if (cityId) {
      return this.apiService.get(`${API_ENDPOINTS.SUPERVISORS}?city_id=${cityId}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.SUPERVISORS}`);
    }
  }

  searchOpManager(searchTerm) {
    return this.apiService.get(`${API_ENDPOINTS.SEARCH_OP_MANAGER}?search_term=${searchTerm}`);
  }

  searchOperator(searchTerm, currentPage, itemPerPage) {
    return this.apiService.get(`${API_ENDPOINTS.SEARCH_OPERATOR}?search_term=${searchTerm}&page=${currentPage}&per_page=${itemPerPage}`);
  }

  getMachinesByClient(clientId, spId, assigned) {
    const params = {
      client_id: clientId || null,
      supervisor_id: spId || null,
      assigned: assigned
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.GET_MACHINE_BY_CLIENT}?${url}`);
  }

  checkUserAttrValidity(param, value) {
    if (param === 'employee_id') {
      return this.apiService.get(`${API_ENDPOINTS.VALIDATE_USER_ATTRS}?employee_code=${value}`);
    } else if (param === 'phone_number') {
      return this.apiService.get(`${API_ENDPOINTS.VALIDATE_USER_ATTRS}?phone_number=${value}`);
    } else if (param === 'adhar_number') {
      return this.apiService.get(`${API_ENDPOINTS.VALIDATE_USER_ATTRS}?adhar_number=${value}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.VALIDATE_USER_ATTRS}?email=${value}`);
    }
  }

  checkClientAttrValidity(param, value) {
    if (param === 'cupboard_name') {
      return this.apiService.get(`${API_ENDPOINTS.VALIDATE_CLIENT_ATTR}?cupboard_name=${value}`);
    }
  }

  deleteSchedule(scheduleId) {
    return this.apiService.delete(`${API_ENDPOINTS.ASSIGN_MACHINE}/${scheduleId}`);
  }

  getUsersData(city_id) {
    const opManagers = this.apiService.get(`${API_ENDPOINTS.OP_MANAGER_BY_CITY}?city_id=${city_id}`);
    const supervisors = this.apiService.get(`${API_ENDPOINTS.SUPERVISORS}?city_id=${city_id}`);
    const operators = this.apiService.get(`${API_ENDPOINTS.OPERATORS}?city_id=${city_id}`);
    const clients = this.apiService.get(`${API_ENDPOINTS.CLIENTS}?city_id=${city_id}`);
    return forkJoin([opManagers, supervisors, operators, clients]);
  }

  getUserWiseCashSummary() {
    return this.apiService.get(`${API_ENDPOINTS.USER_WISE_SUMMARY}`);
  }

  getCatWiseCashSummary() {
    return this.apiService.get(`${API_ENDPOINTS.CATEGORY_WISE_SUMMARY}`);
  }

  getCatWiseSummaryOpbySp(id) {
    return this.apiService.get(`${API_ENDPOINTS.CATEGORY_WISE_SUMMARY_SUP}?user_id=${id}`);
  }

  getCatWiseSummarySup() {
    return this.apiService.get(`${API_ENDPOINTS.CATEGORY_WISE_SUMMARY_SUP}`);
  }


  addCity(cityObj) {
    return this.apiService.post(`${API_ENDPOINTS.CITIES}`, cityObj);
  }

  addMachineScanLimit(value) {
    return this.apiService.post(`${API_ENDPOINTS.MACHINE_SCAN_LIMIT}?machine_scan_distance=${value}`);
  }

  editMachineScanLimit(value) {
    return this.apiService.put(`${API_ENDPOINTS.UPDATE_SCAN_LIMIT}?machine_scan_distance=${value}`);
  }

  getMachineScanLimit() {
    return this.apiService.get(`${API_ENDPOINTS.GET_SCAN_LIMIT}`);
  }

  removeCity(cityId) {
    return this.apiService.delete(`${API_ENDPOINTS.CITIES}/${cityId}`);
  }

  addBrand(brandObj) {
    return this.apiService.post(`${API_ENDPOINTS.BRANDS}`, brandObj);
  }

  removeBrand(brandId) {
    return this.apiService.delete(`${API_ENDPOINTS.BRANDS}/${brandId}`);
  }

  addCategory(categotyObj) {
    return this.apiService.post(`${API_ENDPOINTS.CATEGORIES}`, categotyObj);
  }

  removeCategory(catId) {
    return this.apiService.delete(`${API_ENDPOINTS.CATEGORIES}/${catId}`);
  }

  logout() {
    return this.apiService.delete(`${API_ENDPOINTS.LOGOUT}`);
  }

}
