import { Injectable } from '@angular/core';

import { UtilsService } from './utils.service';
import { JwtService } from './jwt.service';


@Injectable()
export class LocalStorageService {

    constructor(
        private utilsService: UtilsService,
    ) { }

    getItem(key) {
        return window.localStorage.getItem(key) ? JSON.parse(this.utilsService.decode(window.localStorage.getItem(key))) : null;
    }

    setItem(key, value) {
        window.localStorage[key] = this.utilsService.encode(JSON.stringify(value));
        return this.getItem(key);
    }

    removeItem(key) {
        window.localStorage.removeItem(key);
    }

    clearAll() {
        window.localStorage.clear();
    }
}

