import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class InventoryService {
  constructor(private apiService: ApiService) { }

  createProduct(productObj) {
    return this.apiService.post(API_ENDPOINTS.PRODUCT, productObj);
  }

  editProduct(productObj) {
    return this.apiService.put(`${API_ENDPOINTS.UPDATE_PRODUCT_WAREHOUSE}`, productObj);
  }

  createItem(productObj) {
    return this.apiService.post(API_ENDPOINTS.CREATE_ITEM, productObj);
  }

  editItem(productObj, id) {
    return this.apiService.put(`${API_ENDPOINTS.PRODUCT}/${id}`, productObj);
  }

  getProducts(title, sort, searchVal, currentPage, itemPerPage) {
    if (searchVal) {
      return this.apiService.get(`${API_ENDPOINTS.ALL_PRODUCTS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}&search_term=${searchVal}`);
    } else {
      return this.apiService.get(`${API_ENDPOINTS.ALL_PRODUCTS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}`);
    }
  }

  getProductReport(title, sort, searchVal){
    const params = {
      search_term: searchVal || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    
    return this.apiService.get(`${API_ENDPOINTS.ALL_PRODUCTS}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  getProductsByCity(cityId) {
    return this.apiService.get(`${API_ENDPOINTS.PRODUCTS_BY_CITY}?city_id=${cityId}`);
  }

  getCupboards(cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.CUPBOARDS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  getCupboardsByCity(city_id) {
    return this.apiService.get(`${API_ENDPOINTS.CUPBOARDS}?city_id=${city_id}`);
  }

  getWarehouseProducts(prodType, cityId, brandId, catId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      brand_id: brandId || null,
      category_id: catId || null,
      search_term: searchTerm || null,
      type: prodType || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.PRODUCT_BY_WAREHOUSE}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  getWarehouseProductsForReport(prodType, cityId, brandId, catId, searchTerm, title, sort) {
    const params = {
      city_id: cityId || null,
      brand_id: brandId || null,
      category_id: catId || null,
      search_term: searchTerm || null,
      type: prodType || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };

    return this.apiService.get(`${API_ENDPOINTS.PRODUCT_BY_WAREHOUSE_XLSX}?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  getWarProductsByCity(cityId) {
    if (cityId) {
      return this.apiService.get(`${API_ENDPOINTS.PRODUCT_BY_WAREHOUSE}?all_quantities=false&city_id=${cityId}`);
    }
    else {
      return this.apiService.get(`${API_ENDPOINTS.PRODUCT_BY_WAREHOUSE}?all_quantities=false`);
    }
  }

  getCupProducts(prodType, brandId, catId, searchTerm, cupId, title, sort, currentPage, itemPerPage) {
    const params = {
      brand_id: brandId || null,
      category_id: catId || null,
      cupboard_id: cupId || null,
      search_term: searchTerm || null,
      type: prodType || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.PRODUCT_BY_CUPBOARD}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  getCupProductsReport(brandId, catId, searchTerm, cupId, title, sort) {
    const params = {
      brand_id: brandId || null,
      category_id: catId || null,
      cupboard_id: cupId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.PRODUCT_BY_CUPBOARD}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  getCupProductsById(cp_id) {
    return this.apiService.get(`${API_ENDPOINTS.PRODUCT_BY_CUPBOARD}?cupboard_id=${cp_id}`);
  }

  getBrands() {
    return this.apiService.get(`${API_ENDPOINTS.BRANDS}`);
  }

  getCategories() {
    return this.apiService.get(`${API_ENDPOINTS.CATEGORIES}`);
  }

  addProductToCupboard(productObj) {
    return this.apiService.post(`${API_ENDPOINTS.WAREHOUSE_PRODUCT}`, productObj);
  }

  editProductToCupboard(productObj) {
    return this.apiService.put(API_ENDPOINTS.UPDATE_PRODUCT_CUPBOARD, productObj);
  }

  deleteProduct(productId, warehouse_id) {
    return this.apiService.delete(`${API_ENDPOINTS.REMOVE_PRODUCT}?product_id=${productId}&warehouse_id=${warehouse_id}`);
  }

  deleteItem(productId) {
    return this.apiService.delete(`${API_ENDPOINTS.PRODUCT}/${productId}`);
  }

  deleteAllProducts(type, id) {
    if (type === 'warehouse') {
      return this.apiService.delete(`${API_ENDPOINTS.REMOVE_PRODUCTS_FRM_INVENTORY}?city_id=${id}`);
    } else {
      return this.apiService.delete(`${API_ENDPOINTS.REMOVE_PRODUCTS_FRM_INVENTORY}?cupboard_id=${id}`);
    }
  }

  UploadExcel(formData: FormData, type, cityId?, cupId?) {
    let url;
    if (cityId) {
      url = type === 'warehouse' ? `import_warehouses_products?city_id=${cityId}` : `import_cupboard_excel_file?cupboard_id=${cupId}`;
    } else {
      url = type === 'warehouse' ? `import_warehouses_products?city_id=${cityId}` : `import_cupboard_excel_file?cupboard_id=${cupId}`;
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const httpOptions = { headers };
    return this.apiService.post(url, formData, httpOptions);
  }

  onSearchProd(searchTerm) {
    return this.apiService.get(`${API_ENDPOINTS.ALL_PRODUCTS}?search_term=${searchTerm}`);
  }

  UploadProducts(formData: FormData) {
    let url='import_products';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const httpOptions = { headers };
    return this.apiService.post(url, formData, httpOptions);
  }


}
