export const API_ENDPOINTS = {
  LOGIN: 'users/sign_in',
  MACHINES: 'machines',
  MACHINE_TEMPLATE: 'machine_template.xlsx',
  UPDATE_CASH_INFO: 'update_cash_under_machine_or_op',
  CASH_UPDATE_REPORT: 'cash_update_report',
  MACHINE_STOCK_REPORT: 'stock_update_report',
  WALLET: 'wallets',
  MACHINE_CASH_DETAILS: 'cash_details_for_machine',
  LAST_REFILL_AND_CASH_COLLECTED: 'last_refill_and_cash_collected_sales',
  TOP_AND_WORST_PRODUCTS: 'top_and_worst_products',
  REFILLED_MACHINES: 'refilled_machines',
  OPERATIONMANAGERS: 'operator_managers',
  ACCOUNT_MANAGER: 'account_managers',
  TECHNICIAN: 'technician',
  SUPERVISORS: 'supervisors',
  OPERATORS: 'operators',
  REMOVE_OPERATOR: 'delete_upcoming_schedules',
  NOT_WORKING_MACHINES: 'non_working_machines',
  CREATEMACHINE: 'machines',
  GET_CUPS_BY_CLIENT: 'get_cupboards_by_client',
  MACHINE_CONFIG: 'create_machine_configuration',
  MACHINE_CONFIG_BY_ID: 'machine_config_history',
  PRODUCT_CONFIG: 'update_product_count',
  MACHINES_BY_CITY: 'get_machine_by_city',
  ASSIGNUSER: 'assign_user',
  ASSIGN_USER: 'update_upcoming_schedules',
  MACHINEBYTYPE: 'get_machines_by_types',
  MACHINE_BY_STATUS: 'get_machine_status',
  SEARCHMACHINE: 'search_machine',
  MACHINE_SCHEDULES: 'get_allocated_time_slots',
  PRODUCT: 'products',
  REMOVE_PRODUCT: 'products_destroy',
  REMOVE_PRODUCTS_FRM_INVENTORY: 'delete_products_from_warehouse_and_cupboard',
  REMOVE_LEAVE: 'remove_future_leaves',
  PRODUCT_BY_CUPBOARD: 'get_products_for_cupboard',
  SEARCH_OP_MANAGER: 'search_operator_managers',
  SEARCH_OPERATOR: 'search_operators',
  PRODUCT_BY_WAREHOUSE: 'get_products_for_warehouse',
  PRODUCT_BY_WAREHOUSE_XLSX: 'get_products_for_warehouse.xlsx',
  USERS: 'users',
  CLIENTS: 'clients',
  BASE_CLIENT: 'base_clients',
  EDIT: 'edit',
  CHANGE_USER_STATUS: 'change_user_status',
  ASSIGN_MACHINE: 'machine_schedules',
  BRANDS: 'brands',
  NOTIFICATIONS: 'get_notifications',
  CATEGORIES: 'categories',
  PRODUCTS_BY_CITY: 'get_products_by_city_for_cupboard',
  CUPBOARDS: 'all_cupboards',
  CITIES: 'cities',
  MANUFACTURERS: 'manufacturers',
  OP_MANAGER_BY_CITY: 'get_operator_manager_by_city',
  SUPERVISOR_BY_CITY: 'get_supervisor_by_city',
  OPERATOR_BY_CITY: 'get_operator_by_city',
  GET_MACHINE_BY_CLIENT: 'get_machines_by_client',
  GET_MACHINE_NAMES_BY_CLIENT: 'get_machines_names_by_client',
  SUPERVISOR_BY_OPMANAGER: 'get_supervisor_with_operator_manager',
  OPERATOR_BY_SUPERVISOR: 'get_operator_with_supervisor',
  VANS: 'vans',
  SEARCH_VAN: 'search_vans',
  SCHEDULE_VAN: 'warehouses_vans',
  VAN_SCHEDULES: 'van_delivery_schedules',
  ADHOC_VANS: 'get_warehouses_van_by_adhoc',
  SCHEDULE_VAN_TODAY: 'schedule_van_delivery_for_today',
  UPDATE_VAN_DELIVERY: 'warehouses_vans',
  UPDATE_VAN_SCHEDULES: 'update_van_delivery',
  TICKETS: 'machine_issues',
  SEARCH_USER: 'get_user_by_machine',
  UPDATE_WORK_STATUS: 'update_user_status',
  UPDATE_CLIENT_STATUS: 'update_client_status',
  RESOLVED_TICKETS: 'get_machine_issue_by_resolved',
  ADD_PRODUCT_TO_MACHINE: 'products_machines',
  WAREHOUSE_PRODUCT: 'warehouses_products',
  UPDATE_PRODUCT_WAREHOUSE: 'update_product_for_warehouse',
  UPDATE_PRODUCT_CUPBOARD: 'update_product_for_cupboard',
  VALIDATE_CASH_SUBMISSION: 'validate_submitted_cash',
  VALIDATE_USER_ATTRS: 'validate_user_fields',
  VALIDATE_CLIENT_ATTR: 'check_cupboard_existence',
  VALIDATE_MACHINE_ATTR: 'check_machine_existence',
  UNIT_SOLD: 'sales_analytics',
  TEMPLATES: 'find_all_templates',
  USER_WISE_SUMMARY: 'cash_summary_for_om',
  CATEGORY_WISE_SUMMARY: 'category_wise_cash_summary_for_om',
  CATEGORY_WISE_SUMMARY_SUP: 'category_wise_cash_summary_for_supervisor',
  SALES_CHART: 'sales_orders',
  COLLECTED_CHART: 'sales_with_cash_collected',
  EMP_WISE_SALES_REPORT: 'employee_wise_sales',
  MACHINE_WISE_SALES_REPORT: 'machine_wise_sales_report',
  IT_REPORT: 'get_all_sales_request',
  EMP_DETAILS_REPORT: 'instago_employee_details',
  TRANSACTION_SUMMARY_REPORT: 'transaction_summary',
  PAYMENT_REPORT: 'payment_report',
  PAYMENT_SUMMARY_REPORT: 'payment_summary_report',
  CONSOLIDATED_INVOICE_REPORT: 'consolidated_invoice_working',
  EMPLOYEES: 'get_all_employees',
  REFILLED_MACHINE_COUNT: 'refilled_machines_count',
  PRODUCT_WISE_SALES_REPORT: 'product_wise_sales',
  PRODUCT_MACHINE_WISE_SALES_REPORT: 'product_wise_machine_wise_sales',
  DELETE_EMP: 'get_all_employees',
  SOFTWARE_UPDATE: 'machine_softwares',
  UPDATE_MACHINE_STOCK: 'update_stock_under_machine',
  MACHINE_SCAN_LIMIT: 'set_machine_scan_distance',
  UPDATE_SCAN_LIMIT: 'set_machine_scan_distance',
  GET_SCAN_LIMIT: 'get_machine_settings',
  PRODUCT_DETAILS: 'product_details',
  LOGOUT: 'logout',
  SEND_OTP: 'generate_otp_code_for_user',
  VERIFY_OTP: 'verify_otp_code_for_user',
  RESET_PASS: 'reset_password',
  WALLET_REPORT: 'wallet_report',
  EMP_RFID_DETAILS: 'employee_details_rfid_report',
  MONTHLY_SUMMARY: 'yearly_sales_report',
  CAT_BRAND_WISE_SALES: 'category_and_brand_wise_sales_report',
  MACHINE_WISE_DAILY_SALES: 'machine_wise_daily_sales_report',
  MACHINE_WISE_MONTHLY_SALES: 'machine_wise_monthly_sales_report',
  MACHINE_PRODUCT_WISE_MONTHLY_SALES: 'machine_product_wise_monthly_sales_report',
  OPERATOR_VISIT_REPORT: 'operator_visit_report',
  RESET_PASSWORD: 'reset_password',
  REPORTS: 'get_all_reports',
  ALL_PRODUCTS: 'all_products',
  CREATE_ITEM: 'create_product',
  RETURN_PRODUCT_CP: 'add_product_to_slot',
  ROLES: 'all_roles',
  MACHINE_WISE_STOCK: 'stock_update_report',
  MACHINE_WISE_CASH: 'cash_update_report',
  OPERATOR_WISE: 'cash_update_report',
  GET_WALLET_USERS: 'wallets/get_all_instago_wallets',
  CREATE_WALLET_USER: 'wallets/create_instago_wallet',
  UPDATE_WALLET: 'update_instago_wallet',
  DELETE_WALLET_USER: 'delete_instago_wallet',
  GetEmployeSearch: 'get_all_employees',
  GETSALESTRENDS: 'reports/sales_trend',
  GETTOPBRANDSALES: 'reports/top_brand_sales'
};

