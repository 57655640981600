import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { API_ENDPOINTS } from "../constants/api-endpoints.constant";
import { HttpHeaders } from "@angular/common/http";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class SalesService {
  constructor(private apiService: ApiService) { }

  getSalesOrderCount(cityId, frequency, machine_id, month) {
    let url = "";
    if (frequency === "monthly") {
      url = `&filter=${frequency}&month=${month}`;
    } else {
      url = `&filter=${frequency}`;
    }
    if (cityId) {
      if (machine_id) {
        return this.apiService.get(
          `${API_ENDPOINTS.UNIT_SOLD}?city_id=${cityId}&machine_id=${machine_id}${url}`
        );
      } else {
        return this.apiService.get(
          `${API_ENDPOINTS.UNIT_SOLD}?city_id=${cityId}${url}`
        );
      }
    } else {
      if (machine_id) {
        return this.apiService.get(
          `${API_ENDPOINTS.UNIT_SOLD}?machine_id=${machine_id}${url}`
        );
      } else {
        let url = "";
        if (frequency === "monthly") {
          url = `?filter=${frequency}&month=${month}`;
        } else {
          url = `?filter=${frequency}`;
        }
        return this.apiService.get(`${API_ENDPOINTS.UNIT_SOLD}${url}`);
      }
    }
  }

  getRefilledMachineCount(cityId, frequency, machine_id, month) {
    let url = "";
    if (frequency === "monthly") {
      url = `&filter=${frequency}&month=${month}`;
    } else {
      url = `&filter=${frequency}`;
    }
    if (cityId) {
      return this.apiService.get(
        `${API_ENDPOINTS.REFILLED_MACHINE_COUNT}?city_id=${cityId}&machine_id=${machine_id}${url}`
      );
    } else {
      return this.apiService.get(
        `${API_ENDPOINTS.REFILLED_MACHINE_COUNT}?machine_id=${machine_id}${url}`
      );
    }
  }

  getSalesChart(cityId, frequency, machine_id, month) {
    let url = "";
    if (frequency === "monthly") {
      url = `&filter=${frequency}&month=${month}`;
    } else {
      url = `&filter=${frequency}`;
    }
    if (cityId) {
      return this.apiService.get(
        `${API_ENDPOINTS.SALES_CHART}?city_id=${cityId}&machine_id=${machine_id}${url}`
      );
    } else {
      return this.apiService.get(
        `${API_ENDPOINTS.SALES_CHART}?machine_id=${machine_id}${url}`
      );
    }
  }

  getCollectedChart(cityId, frequency, machine_id, month) {
    let url = "";
    if (frequency === "monthly") {
      url = `&filter=${frequency}&month=${month}`;
    } else {
      url = `&filter=${frequency}`;
    }
    if (cityId) {
      return this.apiService.get(
        `${API_ENDPOINTS.COLLECTED_CHART}?city_id=${cityId}&machine_id=${machine_id}${url}`
      );
    } else {
      return this.apiService.get(
        `${API_ENDPOINTS.COLLECTED_CHART}?machine_id=${machine_id}${url}`
      );
    }
  }

  getNotWorkingMachines(cityId, frequency, machine_id, month) {
    let url = "";
    if (frequency === "monthly") {
      url = `&filter=${frequency}&month=${month}`;
    } else {
      url = `&filter=${frequency}`;
    }
    if (cityId) {
      if (machine_id) {
        return this.apiService.get(
          `${API_ENDPOINTS.NOT_WORKING_MACHINES}?city_id=${cityId}&machine_id=${machine_id}${url}`
        );
      } else {
        return this.apiService.get(
          `${API_ENDPOINTS.NOT_WORKING_MACHINES}?city_id=${cityId}${url}`
        );
      }
    } else {
      if (machine_id) {
        return this.apiService.get(
          `${API_ENDPOINTS.NOT_WORKING_MACHINES}?filter=${frequency}&machine_id=${machine_id}`
        );
      } else {
        let url = "";
        if (frequency === "monthly") {
          url = `?filter=${frequency}&month=${month}`;
        } else {
          url = `?filter=${frequency}`;
        }
        return this.apiService.get(
          `${API_ENDPOINTS.NOT_WORKING_MACHINES}${url}`
        );
      }
    }
  }

  getRefilledMachines(cityId, frequency, machine_id, month) {
    let url = "";
    if (frequency === "monthly") {
      url = `&filter=${frequency}&month=${month}`;
    } else {
      url = `&filter=${frequency}`;
    }
    if (cityId) {
      if (machine_id) {
        return this.apiService.get(
          `${API_ENDPOINTS.REFILLED_MACHINES}?city_id=${cityId}&machine_id=${machine_id}${url}`
        );
      } else {
        return this.apiService.get(
          `${API_ENDPOINTS.REFILLED_MACHINES}?city_id=${cityId}${url}`
        );
      }
    } else {
      if (machine_id) {
        return this.apiService.get(
          `${API_ENDPOINTS.REFILLED_MACHINES}?machine_id=${machine_id}${url}`
        );
      } else {
        let url = "";
        if (frequency === "monthly") {
          url = `?filter=${frequency}&month=${month}`;
        } else {
          url = `?filter=${frequency}`;
        }
        return this.apiService.get(`${API_ENDPOINTS.REFILLED_MACHINES}${url}`);
      }
    }
  }

  getTopAndWorstSalesProducts(cityId, frequency, machine_id, month) {
    let url = "";
    if (frequency === "monthly") {
      url = `&filter=${frequency}&month=${month}`;
    } else {
      url = `&filter=${frequency}`;
    }
    if (cityId) {
      return this.apiService.get(
        `${API_ENDPOINTS.TOP_AND_WORST_PRODUCTS}?city_id=${cityId}&machine_id=${machine_id}${url}`
      );
    } else {
      return this.apiService.get(
        `${API_ENDPOINTS.TOP_AND_WORST_PRODUCTS}?machine_id=${machine_id}${url}`
      );
    }
  }

  getReportByType(
    type,
    machineId,
    clientId,
    from,
    to,
    cityId,
    FY,
    month,
    isFY,
    isMonth,
    isDate,
    page,
    recordsperpage,
    accManagerId?
  ) {
    let city_id = cityId && cityId === "all" ? "" : cityId;
    let client_id = clientId && clientId === "all" ? "" : clientId;
    let machine_id = machineId && machineId === "all" ? "" : machineId;
    let mn = isFY || isDate ? "" : `${month.split("-")[1]}-${month.split("-")[2]}`;
    let financial_year = isMonth || isDate ? "" : FY.split("-")[0];
    let frm = isMonth || isFY ? "" : from;
    let t = isMonth || isFY ? "" : to;
    let p = page && recordsperpage ? page : null;
    if (!isMonth && !isFY && !isDate) {
      mn = "";
      financial_year = "";
      from = "";
      to = "";
    }

    let params: any = {
      machine_id: machine_id || "",
      client_id: client_id || "",
      from: frm || "",
      to: t || "",
      financial_year: financial_year || "",
      month: mn || "",
      city_id: city_id || "",
      perpage: recordsperpage || "",
      page: p || ""
    };
    params = _.pickBy(params, _.identity);
    let url = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    let newParams: any = {
      machine_id: machine_id || null,
      from: frm || null,
      to: t || null,
      financial_year: financial_year || "",
      month: mn || "",
      city_id: city_id || null,
      client_id: client_id || "",
      perpage: recordsperpage || null,
      page: p || null
    };
    newParams = _.pickBy(newParams, _.identity);
    let newUrl = Object.keys(newParams)
      .map((key) => key + "=" + newParams[key])
      .join("&");

    switch (type) {
      case "emp_details":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.EMP_DETAILS_REPORT}?${url}`
        );
        break;

      case "machine_wise_stock":
        return this.apiService.updatedGet(`${API_ENDPOINTS.MACHINE_WISE_STOCK}?account_manager_id=${accManagerId}`);
        break;

      case "machine_wise_cash":
        return this.apiService.updatedGet(`${API_ENDPOINTS.MACHINE_WISE_CASH}?type=${type}&account_manager_id=${accManagerId}`);
        break;

      case "operator_wise_cash":
        return this.apiService.updatedGet(`${API_ENDPOINTS.OPERATOR_WISE}?type=operator_wise&account_manager_id=${accManagerId}`);
        break;

      case "employee_rfid_details":
        return this.apiService.updatedGet(`${API_ENDPOINTS.EMP_RFID_DETAILS}?${url}`);
        break;

      case "employee_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.EMP_WISE_SALES_REPORT}?${url}`
        );
        break;

      case "machine_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.MACHINE_WISE_SALES_REPORT}?${newUrl}`
        );
        break;

      case "product_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.PRODUCT_WISE_SALES_REPORT}?${newUrl}`
        );
        break;

      case "product_machine_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.PRODUCT_MACHINE_WISE_SALES_REPORT}?${newUrl}`
        );
        break;

      case "transaction_summary_emp":
        const report_name = 'transaction_summary_employee_wise'
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.TRANSACTION_SUMMARY_REPORT}?${url}&report_name=${report_name}`
        );
        break;

      case "transaction_summary_m":
        const report_name_m = 'transaction_summary_machine_wise'
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.TRANSACTION_SUMMARY_REPORT}?${url}&report_name=${report_name_m}`
        );
        break;

      case "payment_summary":
        return this.apiService.updatedPost(`${API_ENDPOINTS.PAYMENT_REPORT}?${url}`);
        break;

      case "payment_summary_method_wise":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.PAYMENT_SUMMARY_REPORT}?${url}`
        );
        break;
      case "consolidated_invoice":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.CONSOLIDATED_INVOICE_REPORT}?${url}`
        );
        break;
      case "wallet":
        return this.apiService.updatedGet(`${API_ENDPOINTS.WALLET_REPORT}?${url}`);
        break;
      case "monthly_payment_summary":
        return this.apiService.updatedGet(`${API_ENDPOINTS.MONTHLY_SUMMARY}?${url}`);
        break;
      case "category_and_brand_wise_sales_report":
      case "brand_wise_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.CAT_BRAND_WISE_SALES}?${url}`
        );
        break;

      case "machine_wise_daily_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_WISE_DAILY_SALES}?${url}`
        );
        break;

      case "machine_wise_monthly_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_WISE_MONTHLY_SALES}?${url}`
        );
        break;

      case "machine_product_wise_monthly_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_PRODUCT_WISE_MONTHLY_SALES}?${url}`
        );
        break;

      case "operator_visit_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.OPERATOR_VISIT_REPORT}?${url}`
        );
        break;

      default:
        break;
    }
  }

  getSalesReport(
    type,
    machineId,
    clientId,
    from,
    to,
    cityId,
    FY,
    month,
    isFY,
    isMonth,
    isDate,
    page,
    recordsperpage
  ) {
    let city_id = cityId === "all" ? "" : cityId;
    let client_id = clientId === "all" ? "" : clientId;
    let machine_id = machineId === "all" ? "" : machineId;
    let mn = isFY || isDate ? "" : `${month.split("-")[1]}-${month.split("-")[2]}`;
    let financial_year = isMonth || isDate ? "" : FY.split("-")[0];
    let frm = isMonth || isFY ? "" : from;
    let t = isMonth || isFY ? "" : to;
    let p = page && recordsperpage ? page : null;
    if (!isMonth && !isFY && !isDate) {
      mn = "";
      financial_year = "";
      from = "";
      to = "";
    }

    let params: any = {
      machine_id: machine_id || "",
      client_id: client_id || "",
      from: frm || "",
      to: t || "",
      financial_year: financial_year || "",
      month: mn || "",
      city_id: city_id || "",
      perpage: recordsperpage || "",
      page: p || ""
    };
    params = _.pickBy(params, _.identity);
    let url = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    let newParams: any = {
      machine_id: machine_id || null,
      from: frm || null,
      to: t || null,
      financial_year: financial_year || "",
      month: mn || "",
      city_id: city_id || null,
      perpage: recordsperpage || null,
      page: p || null
    };
    newParams = _.pickBy(newParams, _.identity);
    let newUrl = Object.keys(newParams)
      .map((key) => key + "=" + newParams[key])
      .join("&");

    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    const httpOptions = { headers: headers, responseType: "blob" };

    switch (type) {
      case "emp_details":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.EMP_DETAILS_REPORT}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "employee_rfid_details":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.EMP_RFID_DETAILS}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "employee_wise":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.EMP_WISE_SALES_REPORT}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "machine_wise":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_WISE_SALES_REPORT}.xlsx?${newUrl}`,
          httpOptions
        );
        break;

      case "product_wise":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.PRODUCT_WISE_SALES_REPORT}.xlsx?${newUrl}`,
          httpOptions
        );
        break;

      case "product_machine_wise":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.PRODUCT_MACHINE_WISE_SALES_REPORT}.xlsx?${newUrl}`,
          httpOptions
        );
        break;

      case "transaction_summary_emp":
        const report_name = 'transaction_summary_employee_wise'
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.TRANSACTION_SUMMARY_REPORT}.xlsx?${url}&report_name=${report_name}`,
          httpOptions
        );
        break;

      case "transaction_summary_m":
        const report_name_m = 'transaction_summary_machine_wise';
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.TRANSACTION_SUMMARY_REPORT}.xlsx?${url}&report_name=${report_name_m}`,
          httpOptions
        );
        break;

      case "payment_summary":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.PAYMENT_REPORT}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "payment_summary_method_wise":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.PAYMENT_SUMMARY_REPORT}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "consolidated_invoice":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.CONSOLIDATED_INVOICE_REPORT}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "wallet":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.WALLET_REPORT}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "monthly_payment_summary":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MONTHLY_SUMMARY}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "category_and_brand_wise_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.CAT_BRAND_WISE_SALES}.xlsx?${url}`,
          httpOptions
        );
        break;

      case "brand_wise_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.CAT_BRAND_WISE_SALES}.xlsx?brand=true&${url}`,
          httpOptions
        );
        break;

      case "machine_wise_daily_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_WISE_DAILY_SALES}.xlsx?${url}`,
          httpOptions
        );

      case "machine_wise_monthly_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_WISE_MONTHLY_SALES}.xlsx?${url}`,
          httpOptions
        );

      case "machine_product_wise_monthly_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_PRODUCT_WISE_MONTHLY_SALES}.xlsx?${url}`,
          httpOptions
        );

      case "operator_visit_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.OPERATOR_VISIT_REPORT}.xlsx?${url}`,
          httpOptions
        );

      default:
        break;
    }
  }

  sendReportToEmail(
    type,
    machineId,
    clientId,
    from,
    to,
    cityId,
    FY,
    month,
    isFY,
    isMonth,
    isDate,
    page,
    recordsperpage,
    bodyParams,
    accManagerId?
  ) {
    let city_id = cityId === "all" ? "" : cityId;
    let client_id = clientId === "all" ? "" : clientId;
    let machine_id = machineId === "all" ? "" : machineId;
    let mn = isFY || isDate ? "" : `${month.split("-")[1]}-${month.split("-")[2]}`;
    let financial_year = isMonth || isDate ? "" : FY.split("-")[0];
    let frm = isMonth || isFY ? "" : from;
    let t = isMonth || isFY ? "" : to;
    let p = page && recordsperpage ? page : null;
    if (!isMonth && !isFY && !isDate) {
      mn = "";
      financial_year = "";
      from = "";
      to = "";
    }

    let params: any = {
      machine_id: machine_id || "",
      client_id: client_id || "",
      from: frm || "",
      to: t || "",
      financial_year: financial_year || "",
      month: mn || "",
      city_id: city_id || "",
      perpage: recordsperpage || "",
      page: p || null
    };
    params = _.pickBy(params, _.identity);
    let url = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    let newParams: any = {
      machine_id: machine_id || null,
      from: frm || null,
      to: t || null,
      financial_year: financial_year || "",
      month: mn || "",
      city_id: city_id || null,
      perpage: recordsperpage || null,
      page: p || null
    };
    newParams = _.pickBy(newParams, _.identity);
    let newUrl = Object.keys(newParams)
      .map((key) => key + "=" + newParams[key])
      .join("&");

    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json"
    );
    const httpOptions = { headers: headers };

    switch (type) {
      case "emp_details":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.EMP_DETAILS_REPORT}.xlsx?${url}`,
          bodyParams,
          httpOptions
        );
        break;

      case "employee_rfid_details":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.EMP_RFID_DETAILS}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );
        break;
        case "machine_wise_stock":
          return this.apiService.updatedGet(`${API_ENDPOINTS.MACHINE_WISE_STOCK}.xlsx?account_manager_id=${accManagerId}`,httpOptions);
          break;
  
        case "machine_wise_cash":
          return this.apiService.updatedGet(`${API_ENDPOINTS.MACHINE_WISE_CASH}.xlsx?type=${type}&account_manager_id=${accManagerId}`,httpOptions);
          break;
  
        case "operator_wise_cash":
          return this.apiService.updatedGet(`${API_ENDPOINTS.OPERATOR_WISE}.xlsx?type=operator_wise&account_manager_id=${accManagerId}`,httpOptions);
          break;
      case "employee_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.EMP_WISE_SALES_REPORT}.xlsx?${url}`,
          bodyParams,
          httpOptions
        );
        break;

      case "machine_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.MACHINE_WISE_SALES_REPORT}.xlsx?${newUrl}`,
          bodyParams,
          httpOptions
        );
        break;

      case "product_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.PRODUCT_WISE_SALES_REPORT}.xlsx?${newUrl}`,
          bodyParams,
          httpOptions
        );
        break;

      case "product_machine_wise":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.PRODUCT_MACHINE_WISE_SALES_REPORT}.xlsx?${newUrl}`,
          bodyParams,
          httpOptions
        );
        break;

      case "transaction_summary_emp":
  
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.TRANSACTION_SUMMARY_REPORT}.xlsx?${url}&report_name=transaction_summary_employee_wise`,
          bodyParams,
          httpOptions
        );
        break;

      case "transaction_summary_m":
        const report_name = 'transaction_summary_machine_wise'
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.TRANSACTION_SUMMARY_REPORT}.xlsx?${url}&report_name=${report_name}`,
          bodyParams,
          httpOptions
        );
        break;

      case "payment_summary":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.PAYMENT_REPORT}.xlsx?${url}`,
          bodyParams,
          httpOptions
        );
        break;

      case "payment_summary_method_wise":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.PAYMENT_SUMMARY_REPORT}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );
        break;

      case "consolidated_invoice":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.CONSOLIDATED_INVOICE_REPORT}.xlsx?${url}`,
          bodyParams,
          httpOptions
        );
        break;

      case "wallet":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.WALLET_REPORT}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );
        break;

      case "monthly_payment_summary":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MONTHLY_SUMMARY}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );
        break;

      case "category_and_brand_wise_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.CAT_BRAND_WISE_SALES}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );
        break;

      case "brand_wise_sales_report":
        return this.apiService.updatedPost(
          `${API_ENDPOINTS.CAT_BRAND_WISE_SALES}.xlsx?brand=true&${url}`,
          httpOptions
        );
        break;

      case "machine_wise_daily_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_WISE_DAILY_SALES}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );

      case "machine_wise_monthly_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_WISE_MONTHLY_SALES}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );

      case "machine_product_wise_monthly_sales_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.MACHINE_PRODUCT_WISE_MONTHLY_SALES}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );

      case "operator_visit_report":
        return this.apiService.updatedGet(
          `${API_ENDPOINTS.OPERATOR_VISIT_REPORT}.xlsx?${url}&send_to_email=${bodyParams.send_to_email}`,
          httpOptions
        );

      default:
        break;
    }

  }


  sendITReportToEmail(dialogParams) {
    const { city_id: cityId, client_id: clientId, machine_id: machineId, from, to, type, send_to_email } = dialogParams
    let city_id = cityId === "all" ? "" : cityId;
    let client_id = clientId === "all" ? "" : clientId;
    let machine_id = machineId === "all" ? "" : machineId;
    const params = {
      machine_id: machine_id || null,
      client_id: client_id || null,
      city_id: city_id || null,
    };
    let url = "";
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    console.log("calll url:", url)
    let headers = new HttpHeaders();
    // headers.append(
    //   "Content-Type",
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    // );
    const httpOptions = { headers: headers, responseType: "blob" };
    switch (type) {
      case "all":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}.xlsx?send_to_email=${send_to_email}&from=${from}&to=${to}${url}`,
          httpOptions
        );
        break;

      case "add_and_deduct_amount_in_rfid":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}.xlsx?send_to_email=${send_to_email}&service_action=${type}&from=${from}&to=${to}${url}`,
          httpOptions
        );
        break;

      case "sales_updates_to_server":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}.xlsx?send_to_email=${send_to_email}&service_action=${type}&from=${from}&to=${to}${url}`,
          httpOptions
        );
        break;

      case "add_and_deduct_amount_in_wallet":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}.xlsx?send_to_email=${send_to_email}&service_action=${type}&from=${from}&to=${to}${url}`,
          httpOptions
        );
        break;

      default:
        break;
    }
  }
  getITReportByType(type, machineId, clientId, from, to, cityId) {
    let city_id = cityId === "all" ? "" : cityId;
    let client_id = clientId === "all" ? "" : clientId;
    let machine_id = machineId === "all" ? "" : machineId;
    const params = {
      machine_id: machine_id || null,
      client_id: client_id || null,
      city_id: city_id || null,
    };
    let url = "";
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    switch (type) {
      case "all":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}?from=${from}&to=${to}${url}`
        );
        break;

      case "add_and_deduct_amount_in_rfid":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}?service_action=${type}&from=${from}&to=${to}${url}`
        );
        break;

      case "sales_updates_to_server":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}?service_action=${type}&from=${from}&to=${to}${url}`
        );
        break;

      case "add_and_deduct_amount_in_wallet":
        return this.apiService.get(
          `${API_ENDPOINTS.IT_REPORT}?service_action=${type}&from=${from}&to=${to}${url}`
        );
        break;

      default:
        break;
    }
  }
}
