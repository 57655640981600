import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Injectable()
export class VanService {

  constructor(private apiService: ApiService) { }

  createVan(vanDetails) {
    return this.apiService.post(`${API_ENDPOINTS.VANS}`, vanDetails);
  }

  editVan(vanId, vanDetails) {
    return this.apiService.put(`${API_ENDPOINTS.VANS}/${vanId}`, vanDetails);
  }

  editVanSchedule(scheduleId, sceduleDetails) {
    return this.apiService.put(`${API_ENDPOINTS.UPDATE_VAN_SCHEDULES}/${scheduleId}`, sceduleDetails);
  }

  deleteVan(vanId) {
    return this.apiService.delete(`${API_ENDPOINTS.VANS}/${vanId}`);
  }

  deleteSchedule(vanId) {
    return this.apiService.delete(`${API_ENDPOINTS.SCHEDULE_VAN}/${vanId}`);
  }

  searchVan(searchTerm) {
    return this.apiService.get(`${API_ENDPOINTS.SEARCH_VAN}?search_term=${searchTerm}`);
  }

  getVans(cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    // return this.apiService.get(API_ENDPOINTS.VANS);
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.VANS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  getVansByCity(city_id) {
    return this.apiService.get(`${API_ENDPOINTS.VANS}?city_id=${city_id}`);
  }

  scheduleVan(locationDetails) {
    return this.apiService.post(`${API_ENDPOINTS.SCHEDULE_VAN}`, locationDetails);
  }

  updateSchedule(id, schedule) {
    return this.apiService.put(`${API_ENDPOINTS.UPDATE_VAN_DELIVERY}/${id}`, schedule);
  }

  getVanSchedules(cityId, title, sort, filter, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.VAN_SCHEDULES}?sort_title=${title}&sort_type=${sort}&day=${filter}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  // getAdhocVansByAttr(city_id, search_term) {
  //   if (city_id && search_term) {
  //     return this.apiService.get(`${API_ENDPOINTS.ADHOC_VANS}?city_id=${city_id}&search_term=${search_term}`);
  //   } else if (city_id) {
  //     return this.apiService.get(`${API_ENDPOINTS.ADHOC_VANS}?city_id=${city_id}`);
  //   } else {
  //     return this.apiService.get(`${API_ENDPOINTS.ADHOC_VANS}?search_term=${search_term}`);
  //   }
  // }

  getAdhocVans(cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.ADHOC_VANS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  scheduleVanDeliveryToday(schedule) {
    return this.apiService.put(`${API_ENDPOINTS.SCHEDULE_VAN_TODAY}`, schedule);
  }

  getVanAssosiatedDetails(city_id) {
    const operators = this.apiService.get(`${API_ENDPOINTS.OPERATORS}?city_id=${city_id}`);
    const cupboards = this.apiService.get(`${API_ENDPOINTS.CUPBOARDS}?city_id=${city_id}`);
    const vans = this.apiService.get(`${API_ENDPOINTS.VANS}?city_id=${city_id}`);
    return forkJoin([operators, cupboards, vans]);
  }

  UploadExcel(formData: FormData) {
    let url;
    url = `import_van_schedules_excel_file`;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const httpOptions = { headers };
    return this.apiService.post(url, formData, httpOptions);
  }

}
