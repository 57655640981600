import { Router } from '@angular/router';
import { JwtService } from './../../../../core/services/jwt.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/core/services/user.service';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { REGEX_PATTERNS } from 'src/app/core/constants/regex-patterns.constant';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss']
})
export class EditUserModalComponent implements OnInit {

  editUserForm;
  editPhoneForm;
  city_id: any;
  isPhoneUsed: any;

  cpassType = 'password';
  passType = 'password';
  phone_number: any;
  user_id: any;
  @Input() role;

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private userService: UserService,
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private jwtService: JwtService,
    private router: Router
  ) {
    this.user_id = this.localStorageService.getItem('user_id');
  }

  ngOnInit() {
    this.initializeForm();
  }

  closeDialog() {
    this.activeModal.dismiss('dismissed');
  }

  initializeForm() {

    this.phone_number = this.localStorageService.getItem('phone');
    this.editUserForm = this.fb.group({
      // phone_number: [this.phone_number, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      password: ['', [Validators.required, Validators.pattern(REGEX_PATTERNS.PASSWORD)]],
      confirm_password: ['', Validators.required],
    },
      { validator: this.confirmPassword }
    );

    this.editPhoneForm = this.fb.group({
      current_number: [{ value: this.phone_number, disabled: true }],
      phone_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]]
    });

  }

  confirmPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmNewPassword = control.get('confirm_password').value;
    if (password && confirmNewPassword) {
      if (password !== confirmNewPassword) {
        control.get('confirm_password').setErrors({ MatchPassword: true });
      } else {
        control.get('confirm_password').setErrors(null);
      }
    }
    return;
  }

  checkUserAttrValidity(control) {
    const value = this.editPhoneForm.get(control).value;
    if (value.length >= 10) {
      this.userService.checkUserAttrValidity(control, value).subscribe((res) => {
        if (res.status === 'success') {
          if (control === 'phone_number') {
            this.isPhoneUsed = res.exist;
          }
          this.setValidity(control);
        } else {
          console.log(res.message, 'Error !');
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      return;
    }
  }

  setValidity(type) {
    if (type === 'phone_number' && this.isPhoneUsed) {
      this.editPhoneForm.controls['phone_number'].setErrors({ 'isUsed': true });
      this.editPhoneForm.controls['phone_number'].markAsTouched();
    }
    return;
  }


  onSubmit() {
    this.editUser();
  }

  togglePassType() {
    this.passType = this.passType === 'password' ? 'text' : 'password';
  }

  toggleCPassType() {
    this.cpassType = this.cpassType === 'password' ? 'text' : 'password';
  }

  editUser() {
    const { confirm_password, ...userObj } = this.editUserForm.value;
    const userDetails = {
      user: {
        ...userObj,
      }
    };

    this.userService.editUser(this.user_id, userDetails).subscribe((res) => {
      if (res.status === 'success') {
        this.activeModal.close('edited');
        // this.router.navigate(['/operators'], { queryParams: { page: 1, search: '' }});
        this.toastr.success('Password changed successfully!', 'Success !');
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  editPhone() {
    this.checkUserAttrValidity('phone_number')
    const userDetails = {
      user: {
        phone_number: this.editPhoneForm.value.phone_number
      }
    };

    this.userService.editUser(this.user_id, userDetails).subscribe((res) => {
      if (res.status === 'success') {
        this.activeModal.close('edited');
        this.toastr.success('Phone Number changed successfully!');
        this.toastr.info('Please Login again with new Phone Number!');
        this.logout();
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  logout() {
    this.userService.logout();
    this.jwtService.destroyTokenAndEmail();
    this.router.navigate(['/login']);
  }

}
