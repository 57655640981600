export const FULL_DAY_SLOTS = [{ value: '00:00:00-00:30:00', id: 1, format: '00:00:00-00:30:00' },
{ value: '00:30:00-00:01:00', id: 2, format: '00:30:00-00:01:00' },
{ value: '01:00:00-01:30:00', id: 3, format: '01:00:00-01:30:00' },
{ value: '01:30:00-02:00:00', id: 4, format: '01:30:00-02:00:00' },
{ value: '02:00:00-02:30:00', id: 5, format: '02:00:00-02:30:00' },
{ value: '02:30:00-03:00:00', id: 6, format: '02:30:00-03:00:00' },
{ value: '03:00:00-03:30:00', id: 7, format: '03:00:00-03:30:00' },
{ value: '03:30:00-04:00:00', id: 8, format: '03:30:00-04:00:00' },
{ value: '04:00:00-04:30:00', id: 9, format: '04:00:00-04:30:00' },
{ value: '04:30:00-05:00:00', id: 10, format: '04:30:00-05:00:00' },
{ value: '05:00:00-05:30:00', id: 11, format: '05:00:00-05:30:00' },
{ value: '05:30:00-06:00:00', id: 12, format: '05:30:00-06:00:00' },
{ value: '06:00:00-06:30:00', id: 13, format: '06:00:00-06:30:00' },
{ value: '06:30:00-07:00:00', id: 14, format: '06:30:00-07:00:00' },
{ value: '07:00:00-07:30:00', id: 15, format: '07:00:00-07:30:00' },
{ value: '07:30:00-08:00:00', id: 16, format: '07:30:00-08:00:00' },
{ value: '08:00:00-08:30:00', id: 17, format: '08:00:00-08:30:00' },
{ value: '08:30:00-09:00:00', id: 18, format: '08:30:00-09:00:00' },
{ value: '09:00:00-09:30:00', id: 19, format: '09:00:00-09:30:00' },
{ value: '09:30:00-10:00:00', id: 20, format: '09:30:00-10:00:00' },
{ value: '10:00:00-10:30:00', id: 21, format: '10:00:00-10:30:00' },
{ value: '10:30:00-11:00:00', id: 22, format: '10:30:00-11:00:00' },
{ value: '11:00:00-11:30:00', id: 23, format: '11:00:00-11:30:00' },
{ value: '11:30:00-12:00:00', id: 24, format: '11:30:00-12:00:00' },

{ value: '00:00:00-00:30:00', id: 25, format: '12:00:00-12:30:00' },
{ value: '00:30:00-00:01:00', id: 26, format: '12:30:00-13:00:00' },
{ value: '01:00:00-01:30:00', id: 27, format: '13:00:00-13:30:00' },
{ value: '01:30:00-02:00:00', id: 28, format: '13:30:00-14:00:00' },
{ value: '02:00:00-02:30:00', id: 29, format: '14:00:00-14:30:00' },
{ value: '02:30:00-03:00:00', id: 30, format: '14:30:00-15:00:00' },
{ value: '03:00:00-03:30:00', id: 31, format: '15:00:00-15:30:00' },
{ value: '03:30:00-04:00:00', id: 32, format: '15:30:00-16:00:00' },
{ value: '04:00:00-04:30:00', id: 33, format: '16:00:00-16:30:00' },

{ value: '04:30:00-05:00:00', id: 34, format: '16:30:00-17:00:00' },
{ value: '05:00:00-05:30:00', id: 35, format: '17:00:00-17:30:00' },
{ value: '05:30:00-06:00:00', id: 36, format: '17:30:00-18:00:00' },
{ value: '06:00:00-06:30:00', id: 37, format: '18:00:00-18:30:00' },
{ value: '06:30:00-07:00:00', id: 38, format: '18:30:00-19:00:00' },
{ value: '07:00:00-07:30:00', id: 39, format: '19:00:00-19:30:00' },
{ value: '07:30:00-08:00:00', id: 40, format: '19:30:00-20:00:00' },
{ value: '08:00:00-08:30:00', id: 41, format: '20:00:00-20:30:00' },
{ value: '08:30:00-09:00:00', id: 42, format: '20:30:00-21:00:00' },
{ value: '09:00:00-09:30:00', id: 43, format: '21:00:00-21:30:00' },
{ value: '09:30:00-10:00:00', id: 44, format: '21:30:00-22:00:00' },
{ value: '10:00:00-10:30:00', id: 45, format: '22:00:00-22:30:00' },
{ value: '10:30:00-11:00:00', id: 46, format: '22:30:00-23:00:00' },
{ value: '11:00:00-11:30:00', id: 47, format: '23:00:00-23:30:00' },
{ value: '11:30:00-12:00:00', id: 48, format: '23:30:00-24:00:00' }];
