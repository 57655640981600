import { EditUserModalComponent } from './../layout/components/sidebar/edit-user-modal/edit-user-modal.component';
import { AssignMachineToUserComponent } from './../layout/operators/assign-machine-to-user/assign-machine-to-user.component';
import { SalesService } from './../core/services/sales.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { ApiService } from '../core/services/api.service';
import { AuthService } from '../core/services/auth.service';
import { JwtService } from '../core/services/jwt.service';
import { LocalStorageService } from '../core/services/localstorage.service';
import { UtilsService } from './../core/services/utils.service';
import { MachinesService } from '../core/services/machines.service';
import { InventoryService } from '../core/services/inventory.service';
import { UserService } from '../core/services/user.service';
import { ClientService } from '../core/services/client.service';
import { VanService } from '../core/services/van.service';
import { NotificationsService } from '../core/services/notifications.service';
import { NumberOnlyDirective } from '../core/directives/number-only.directive';
import { AlphabetsOnlyDirective } from './../core/directives/alphabets-only.directive';
import { NgPipesModule } from 'ngx-pipes';
import { TagInputModule } from 'ngx-chips';
import { UserWiseSummaryComponent } from '../layout/operation-managers/user-wise-summary/user-wise-summary.component';
import { CategoryWiseSummaryComponent } from '../layout/operation-managers/category-wise-summary/category-wise-summary.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { OrderModule } from 'ngx-order-pipe';
import { EditAssignMachineToUserComponent } from '../layout/operators/edit-assign-machine-to-user/edit-assign-machine-to-user.component';
import { DeleteAssignMachineComponent } from '../layout/operators/delete-assign-machine/delete-assign-machine.component';
import { ErrorsModalComponent } from '../layout/components/errors-modal/errors-modal.component';


@NgModule({
  declarations: [NumberOnlyDirective,
    AlphabetsOnlyDirective,
    AssignMachineToUserComponent,
    EditAssignMachineToUserComponent,
    ErrorsModalComponent,
    DeleteAssignMachineComponent,
    UserWiseSummaryComponent,
    CategoryWiseSummaryComponent,
    EditUserModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularMultiSelectModule,
    NgbModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgPipesModule,
    TagInputModule,
    NgxChartsModule,
    OrderModule,
    NgCircleProgressModule.forRoot({
      radius: 60,
      outerStrokeWidth: 20,
      innerStrokeWidth: 20,
      showUnits: false,
      innerStrokeColor: '#e7e8ea',
      outerStrokeColor: '#46BE88',
      showSubtitle: false,
      backgroundStrokeWidth: 19,
      space: -20,
      maxPercent: 100,
      outerStrokeLinecap: 'inherit',
      titleFontSize: '18',
      unitsFontSize: '18',
      animationDuration: 300,
      showInnerStroke: true,
    }),  // BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ToastrModule.forRoot()
  ],
  exports: [
    FormsModule,
    AngularMultiSelectModule,
    ReactiveFormsModule,
    NumberOnlyDirective,
    NgPipesModule,
    TagInputModule,
    NgbModule,
    NgxChartsModule,
    NgCircleProgressModule,
    OwlDateTimeModule,
    OrderModule,
    OwlNativeDateTimeModule,
    AlphabetsOnlyDirective,
    AssignMachineToUserComponent,
    EditAssignMachineToUserComponent,
    ErrorsModalComponent,
    DeleteAssignMachineComponent,
    UserWiseSummaryComponent,
    CategoryWiseSummaryComponent
  ],
  providers: [LocalStorageService,
    JwtService,
    AuthService,
    ApiService,
    UtilsService,
    MachinesService,
    InventoryService,
    UserService,
    ClientService,
    VanService,
    NotificationsService,
    SalesService],
  entryComponents: [AssignMachineToUserComponent, ErrorsModalComponent, EditAssignMachineToUserComponent,
    DeleteAssignMachineComponent,
    EditUserModalComponent]
})
export class SharedModule { }
