import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constant';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClientService {

  constructor(private apiService: ApiService) { }

  getClientsByCity(cityId?, base_clientId?) {
    let city_id = cityId === 'all' ? null : cityId;
    let base_Id = base_clientId ? base_clientId : null;

    const params = {
      city_id: city_id || null,
      base_client_id: base_Id || null,
    };

    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.CLIENTS}?avoid_inactive=true${url}`);
  }

  getClientsByCities(cityIds) {
    let city_id = cityIds;

    const params = {
      city_id: cityIds || null,
    };

    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=[${params[key]}]`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.CLIENTS}?avoid_inactive=true${url}`);
  }

  getRoles(){
    return this.apiService.get(`${API_ENDPOINTS.ROLES}`);
  }

  getClients(client_id, cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
      base_client_id: client_id || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.CLIENTS}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  // 
  getEmployeClients(client_id, cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      instago_card_number: searchTerm || null,
      base_client_id: client_id || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.GetEmployeSearch}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  getClientReport(client_id, cityId, searchTerm, title, sort){
    const params = {
      city_id: cityId || null,
      search_term: searchTerm || null,
      base_client_id: client_id || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.CLIENTS}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }

  getBaseClients() {
    return this.apiService.get(`${API_ENDPOINTS.BASE_CLIENT}`);
  }

  editBaseClient(clientId, clientObj) {
    return this.apiService.put(`${API_ENDPOINTS.BASE_CLIENT}/${clientId}`, clientObj);
  }

  editClient(clientId, clientObj) {
    return this.apiService.put(`${API_ENDPOINTS.CLIENTS}/${clientId}`, clientObj);
  }

  addClient(clientObj) {
    return this.apiService.post(`${API_ENDPOINTS.CLIENTS}`, clientObj);
  }

  addBaseClient(clientObj) {
    return this.apiService.post(`${API_ENDPOINTS.BASE_CLIENT}`, clientObj);
  }

  deleteClient(clientId) {
    return this.apiService.delete(`${API_ENDPOINTS.USERS}/${clientId}`);
  }

  changeUserStatus(clientId){
    return this.apiService.put(`${API_ENDPOINTS.USERS}/${clientId}/${API_ENDPOINTS.CHANGE_USER_STATUS}`);
  }

  getBaseClient(id){
    return this.apiService.get(`${API_ENDPOINTS.BASE_CLIENT}/${id}/${API_ENDPOINTS.EDIT}`);
  }

  addEmp(data) {
    return this.apiService.post(`${API_ENDPOINTS.WALLET}`, data);
  }

  editEmp(data, id) {
    return this.apiService.put(`${API_ENDPOINTS.WALLET}/${id}`, data);
  }

  deleteEmp(id) {
    return this.apiService.delete(`${API_ENDPOINTS.WALLET}/${id}`);
  }

  deleteAllEmps(city_id) {
    return this.apiService.delete(`${API_ENDPOINTS.DELETE_EMP}?city_id=${city_id}`);
  }

  uploadClient(formData: FormData, cityId) {
    let url;
    if (cityId) {
      url = `import_client_excel_sheet?city_id=${cityId}`;
    } else {
      url = 'import_client_excel_sheet';
    }
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const httpOptions = { headers };
    return this.apiService.post(url, formData, httpOptions);
  }

  uploadEmployees(formData: FormData) {
    let url;
    url = 'import_wallet_excel_sheet';
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const httpOptions = { headers };
    return this.apiService.post(url, formData, httpOptions);
  }

  getEmployees(base_id, clientId, cityId, searchTerm, title, sort, currentPage, itemPerPage) {
    const params = {
      city_id: cityId || null,
      client_id: clientId || null,
      search_term: searchTerm || null,
      base_client_id: base_id || null
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.EMPLOYEES}?sort_title=${title}&sort_type=${sort}&page=${currentPage}&per_page=${itemPerPage}${url}`);
  }

  addWalletUser(data) {
    return this.apiService.post(`${API_ENDPOINTS.CREATE_WALLET_USER}`, data);
  }

  getWalletUsers(title,sort){
    const params = {};
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }
    return this.apiService.get(`${API_ENDPOINTS.GET_WALLET_USERS}?sort_title=${title}&sort_type=${sort}${url}`);
  }

  deleteWalletUser(id) {
    return this.apiService.delete(`${API_ENDPOINTS.WALLET}/${id}/${API_ENDPOINTS.DELETE_WALLET_USER}`);
  }

  editWalletUser(data, id) {
    return this.apiService.put(`${API_ENDPOINTS.WALLET}/${id}/${API_ENDPOINTS.UPDATE_WALLET}`, data);
  }

  getEmpReport(clientId, cityId, searchTerm, title, sort) {
    const params = {
      city_id: cityId || null,
      client_id: clientId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.EMPLOYEES}.xlsx?sort_title=${title}&sort_type=${sort}${url}`, httpOptions);
  }



// sar
  getWallatUserlist(clientId, cityId, searchTerm, title, sort) {
    const params = {
      city_id: cityId || null,
      client_id: clientId || null,
      search_term: searchTerm || null,
    };
    let url = '';
    for (const key in params) {
      if (params[key] !== null) {
        url = url + `&${key}=${params[key]}`;
      }
    }

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const httpOptions = { headers: headers, responseType: 'blob' };
    return this.apiService.get(`${API_ENDPOINTS.GET_WALLET_USERS}.xlsx?sort_title=${title}&sort_type=${sort}${url}`,httpOptions);
  }

}
