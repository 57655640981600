import { Injectable, Injector, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class ApiService {
    constructor(
        private _http: HttpClient,
        private toastr: ToastrService
    ) {

    }

    baseUrl = environment.baseUrl;

    get(path: string, options?): Observable<any> {
        
        const url = `${this.baseUrl}${path}`;
        let params = new HttpParams();
        
        return this._http.get<any>(url, options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    put(path: string, body: Object = {}): Observable<any> {
        const url = `${this.baseUrl}${path}`;
        return this._http
            .put(url, body)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    patch(path: string, body: Object = {}): Observable<any> {
        const url = `${this.baseUrl}${path}`;
        return this._http
            .patch(url, body)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    post(path: string, body: Object = {}, options?): Observable<any> {
        const url = `${this.baseUrl}${path}`;
        return this._http
            .post(url, body, options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    updatedGet(path: string, options?): Observable<any> {
        
        const url = `${this.baseUrl}${path}`;
        let params = new HttpParams();
        
        return this._http.get<any>(url,options )
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    updatedPost(path: string, body: Object = {}, options?): Observable<any> {
        const url = `${this.baseUrl}${path}`;
        return this._http
            .post(url, body, options)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    delete(path, body: Object = {}): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: body
        };
        const url = `${this.baseUrl}${path}`;
        return this._http
            .delete(url, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }


    // Error handling 
    handleError = (error) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.status === 500) {  
                this.toastr.error('', 'Server side error');
            } else if (error.status === 401) {
                this.toastr.error('', error.error.error);
            } else {
                console.log(error.status);
            }
            // alert(errorMessage);
        }
        return throwError(errorMessage);
    }

}
