import { Router } from '@angular/router';
import { MachinesService } from './../../../core/services/machines.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-user-wise-summary',
  templateUrl: './user-wise-summary.component.html',
  styleUrls: ['./user-wise-summary.component.scss']
})
export class UserWiseSummaryComponent implements OnInit {
  user_role: any;
  city_id: any;
  opManager: any;
  operators: any;
  machines: any;
  step: any = 1;
  op = '';
  spId: any;
  opId: any;
  isOM: any = true;
  isSP: any = false;
  isOP: any = false;
  isMN: any = false;

  exportAsConfig: ExportAsConfig = {
    type: 'csv', // the type you want to download
    elementIdOrContent: 'cash-summary', // the id of html/table element
  };

  constructor(private userService: UserService,
    private toastr: ToastrService,
    private exportAsService: ExportAsService,
    private localStorageService: LocalStorageService,
    private machinesService: MachinesService,
    private router: Router
  ) {
    this.user_role = this.localStorageService.getItem('role');
    if (this.user_role.user_role_name === 'operator_manager') {
      this.city_id = this.localStorageService.getItem('city_id');
    }
  }

  ngOnInit() {
    this.getUserWiseCashSummary();
  }

  getUserWiseCashSummary() {
    this.userService.getUserWiseCashSummary().subscribe((res) => {
      if (res.status === 'success') {
        this.opManager = res.operator_manager;
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  setStep(step, type?, id?) {
    this.step = step;
    this.setBreadcrumb();
    if (type && id) {
      switch (type) {
        case 'sp':
          this.spId = id;
          this.getOpBySpId(id);
          break;
        case 'op':
          this.opId = id;
          this.getMachibeByOpId(id);
          break;
        default:
          break;
      }
    } else if (type) {
      switch (type) {
        case 'allop':
          this.getOperators();
          break;
        case 'allmc':
          this.getMachines();
          break;
        default:
          break;
      }
    }
  }

  setBreadcrumb() {
    switch (this.step) {
      case 1:
        this.isOM = true;
        this.isSP = false;
        this.isOP = false;
        this.isMN = false;
        break;
      case 2:
        this.isOM = true;
        this.isSP = true;
        this.isOP = false;
        this.isMN = false;
        // this.getOpBySpId(this.spId);
        break;
      case 3:
        this.isOM = true;
        this.isSP = true;
        this.isOP = true;
        this.isMN = false;
        // this.getMachibeByOpId(this.opId);
        break;
      case 4:
        this.isOM = true;
        this.isSP = true;
        this.isOP = true;
        this.isMN = true;
        break;
      default:
        break;
    }
  }

  getOpBySpId(id) {
    this.userService.getOperatorBySup(id).subscribe((res) => {
      if (res.status === 'success') {
        this.operators = res.operators;
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  getMachibeByOpId(id) {
    this.machinesService.getMachibeByOpId(id).subscribe((res) => {
      if (res.status === 'success') {
        this.machines = res.machines;
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  getMachines() {
    this.machinesService.getMachinesByCity(this.city_id).subscribe((res) => {
      if (res.status === 'success') {
        this.machines = res.machines;
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  getOperators() {
    this.userService.getOperatorsByCity(this.city_id).subscribe((res) => {
      if (res.status === 'success') {
        this.operators = res.operators;
      } else {
        this.toastr.error(res.message, 'Error !');
      }
    }, (err) => {
      console.log(err);
    });
  }

  exportFile() {
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'testfilecsv').subscribe(() => {
      // save started
    });
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    this.exportAsService.get(this.exportAsConfig).subscribe(content => {
      console.log(content);
    });
  }

  totalPendingCashCountSp(machines) {
    const totalMachines = machines;
    let sum = totalMachines.map(item => item.cash_to_be_collected).reduce((prev, next) => prev + next);
    return sum;
  }

  totalPendingCashCountOp(machines) {
    const totalMachines = machines;
    let sum = totalMachines.map(item => item.machine[0].cash_to_be_collected).reduce((prev, next) => prev + next);
    return sum;
  }
}
