import { Injectable } from '@angular/core';

import { LocalStorageService } from './localstorage.service';

@Injectable()
export class JwtService {

    constructor(
        private _localStorageService: LocalStorageService
    ) { }

    getToken(): String {
        return this._localStorageService.getItem('token');
    }

    setToken(token: String) {
        this._localStorageService.setItem('token', token);
    }

    destroyTokenAndEmail() {
        this._localStorageService.clearAll();
    }
}
