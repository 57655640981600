import { LocalStorageService } from './../../core/services/localstorage.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivateChild {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const useRole = this.localStorageService.getItem('role');
    const token = localStorage.getItem('token');
    if (token) {
      if (childRoute.data.roles.indexOf(useRole.user_role_name) > -1) {
        return true;
      } else {

        if (useRole.user_role_name === 'technician') {
          this.router.navigate(['/notifications']);
          return true;
        }
        if (useRole.user_role_name === 'client') {
          this.router.navigate(['/clients/list']);
          return true;
        }

        if (useRole.user_role_name === 'account_manager') {
          this.router.navigate(['/account-managers']);
          return true;
        }

        this.router.navigate(['/dashboard/analytics']);
        return false;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}


